import React from "react";

const EllipsisText = ({ text, maxLength }) => {
  if (!text) {
    return null;
  }

  if (text.length <= maxLength) {
    return <p className="text-sm font-normal font-poppins text-accent-color"><span dangerouslySetInnerHTML={{ __html: text }} /></p>;
  }

  const truncatedText = text.slice(0, maxLength) + "...";

  return (
    <div className="flex items-center cursor-pointer">
  
      <p className="text-sm font-normal font-poppins text-accent-color ">
     <span dangerouslySetInnerHTML={{ __html: truncatedText }} /><span className="mt-1 ml-1 text-xs font-medium underline font-poppins text-secondary 3xl:text-sm">Read more</span>
      </p>
    </div>
  );
};

export default EllipsisText;
