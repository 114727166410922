import { useState, useLayoutEffect, useRef, useEffect } from "react";

// ===================== React Router DOM ======================== //
import { NavLink } from "react-router-dom";

// ===================== Scroll ======================== //
import { scroll } from "../helperFunctions/scroll";

// ===================== Routes ======================== //
import routes from "../routes/sidebar";

// ===================== Context ======================== //
import { useLoggedContext } from "../context/provider";

// ===================== Admin Navbar ======================== //
import AdminNavbar from "../components/AdminNavbar";
import ConfirmationModal from "./ConfirmationModal";
import NotificationCircle from "./NotificationCircle";

export default function Sidebar({ logout }) {
  const ref = useRef(null);

  const { logOut, showSidebar, setShowSidebar, showBackDrop, setShowBackDrop, user, pendingCount } = useLoggedContext();
  // console.log("pendingCount==>", pendingCount);

  const [logoutAlert, setLogoutAlert] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(0);
  const [loading, setLoading] = useState(false);

  // Logout Modal
  const showLogout = (e) => {
    e.preventDefault();
    setLogoutAlert(true);
    scroll(false);
  };

  // Handle Logout
  const handleLogout = () => {
    setLogoutAlert(false);
    scroll(true);
    setLoading(true);
    logOut();
  };

  // Use Layout Effects
  useLayoutEffect(() => {
    setSidebarWidth(ref.current.offsetWidth);
    // getPendingSettings();
  }, []);

  // Use Effects
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowSidebar(false);
        setShowBackDrop(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const filteredRoutes = routes.filter((route) => {
    const d1 = user?.permissions?.includes("super_panel_overview");
    const d2 = user?.permissions?.includes("super_panel_subscription_sales");
    const d3 = user?.permissions?.includes("super_panel_ticket_analystics");
    const d4 = user?.permissions?.includes("super_panel_subscription_analytics");

    const s1 = user?.permissions?.includes("super_panel_accept_decline_request");
    const s2 = user?.permissions?.includes("super_panel_block_unblock_setting");
    const s3 = user?.permissions?.includes("super_panel_add_new_setting");
    const s4 = user?.permissions?.includes("super_panel_edit_setting");

    const t1 = user?.permissions?.includes("super_panel_view_ticket");
    const t2 = user?.permissions?.includes("super_panel_response_ticket");
    const t3 = user?.permissions?.includes("super_panel_edit_delete_ticket");
    const t4 = user?.permissions?.includes("super_panel_change_status");

    const p1 = user?.permissions?.includes("super_panel_view_balance");
    const p2 = user?.permissions?.includes("super_panel_view_projection");
    const p3 = user?.permissions?.includes("super_panel_transaction_history");

    const u = user?.permissions?.includes("super_panel_user");

    const sb = user?.permissions?.includes("super_panel_subcription_plan");

    if (route.path === "dashboard") {
      return d1 || d2 || d3 || d4 || user?.role === "super_admin";
    }

    if (route.path === "settings") {
      return s1 || s2 || s3 || s4 || user?.role === "super_admin";
    }

    if (route.path === "support-tickets") {
      return t1 || t2 || t3 || t4 || user?.role === "super_admin";
    }

    if (route.path === "payments") {
      return p1 || p2 || p3 || user?.role === "super_admin";
    }

    if (route.path === "users") {
      return u || user?.role === "super_admin";
    }

    if (route.path === "subscriptions-plans") {
      return sb || user?.role === "super_admin";
    }

    return true;
  });

  return (
    <>
      {showBackDrop && <div class="absolute left-0 top-0 z-10 h-full w-full bg-black/50"></div>}
      <AdminNavbar
        sidebarWidth={sidebarWidth}
        setSidebarWidth={setSidebarWidth}
        setShowSidebar={setShowSidebar}
        showSidebar={showSidebar}
        setShowBackDrop={setShowBackDrop}
      />
      <div
        className={`fixed top-[10vh] h-screen bg-white lg:left-0 lg:bg-transparent 2xl:top-[8vh] ${showSidebar === "left-0" ? "left-0 " : "-left-64"} z-10 w-64 flex-row flex-nowrap overflow-hidden  overflow-y-auto shadow-card transition-all duration-300 md:w-56 md:shadow-none 2xl:w-64`}
        id="sidebar"
        ref={ref}
      >
        <div className="relative flex-col items-stretch min-h-full px-0 flex-nowrap">
          <div className="flex flex-col py-8 pr-5 md:pr-0 2xl:py-10">
            <h5 className="w-full text-xl font-bold tracking-wider uppercase d-flex text-primary">
            </h5>
            <ul className="flex flex-col min-w-full list-none">
              {filteredRoutes.map((route, index) => (
                <li key={index} className="mb-3 rouned-full group 2xl:mb-4">
                  <NavLink
                    key={route.id}
                    to={route.path === logout ? "" : route.path}
                    onClick={(e) => (route.path === logout ? showLogout(e) : "")}
                    className={`flex items-center rounded-r-full py-2 pl-5 text-[12px] ${route.path === logout ? "underline-offset-4 group-hover:underline" : "group-hover:bg-gray-300 "} font-normal text-grayColor300 transition-all duration-200 2xl:text-[14px]`}
                  >
                    {route.icon && <img src={route.icon} alt="user group" className="object-contain w-5 h-5 mr-2 lg:mr-3 2xl:mr-4" />}
                    {route.name}
                    {route.path === "settings" && pendingCount !== 0 && (
                      <div className="ml-2 ">
                        <NotificationCircle notificationCount={pendingCount ?? 0} />
                      </div>
                    )}
                  </NavLink>
                </li>
              ))}
            </ul>

            {/* Logout Modal */}
            <ConfirmationModal label="Sign Out" isOpen={logoutAlert} setIsOpen={setLogoutAlert} handleSubmit={handleLogout} />
          </div>
        </div>
      </div>
    </>
  );
}
