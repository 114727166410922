import { axios } from "../axios";
import { POST, GET } from "../endpoints";

export const getSubscriptionPlans = async (data) => {
  try {
    let response = await axios.get(GET.GET_SUBSCRIPTION_PLANS, {
      params: data,
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const getSubscriptionPlansHistory = async (data) => {
  try {
    let response = await axios.get(GET.GET_SUBSCRIPTION_PLANS_HISTORY, {
      params: data,
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const updateSubscriptionFreeTrial = async (data) => {
  try {
    let response = await axios.post(POST.UPDATE_FREE_TRIAL, data);
    return response;
  } catch (err) {
    throw err;
  }
};

export const updateSubscriptionFreeTrialDate = async (data) => {
  try {
    let response = await axios.post(POST.UPDATE_FREE_TRIAL_DATE, data);
    return response;
  } catch (err) {
    throw err;
  }
};
