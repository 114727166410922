import { axios } from "../axios";
import { POST, GET } from "../endpoints";



export const getEmployeeDashboardStats = async (data) => {
    try {
      let response = await axios.get(GET.GET_EMPLOYEE_DASHBOARD_STATS, {
        params: data
      });
      return response;
    } catch (err) {
      throw err;
    }
  };

  export const getDashboardUsersStats = async (data) => {
    try {
      let response = await axios.get(GET.GET_DASHBOARD_USERS_STATS, {
        params: data,
      });
      return response;
    } catch (err) {
      throw err;
    }
  };
  
  
  export const getCampuses = async (data) => {
    try {
      let response = await axios.get(GET.GET_CAMPUSES_LIST, {
        params: data,
      });
      return response;
    } catch (err) {
      throw err;
    }
  };
  
  export const getDepartments = async (data) => {
    try {
      let response = await axios.get(GET.GET_DEPARTMENT_LIST, {
        params: data,
      });
      return response;
    } catch (err) {
      throw err;
    }
  };
  
