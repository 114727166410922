import React from "react";

// ===================== React Router DOM ====================== //
import { Navigate } from "react-router-dom";

// ===================== Pages ====================== //
import Login from "../pages/Login";
import ForgotPassword from "../pages/ForgotPassword";

// ===================== Routes ====================== //
import {
  login,
  logout,
  users,
  settings,
  payments,
  dashboard,
  resetPassword,
  forgotPassword,
  supportTickets,
  subscriptionsPlans,
  settingDetail,
  analytics,
} from "./routesDefined";

import Users from "../pages/Users";
import Settings from "../pages/Settings";
import Payments from "../pages/Payments";
import Dashboard from "../pages/Dashboard";
import ResetPassword from "../pages/ResetPassword";
import SupportTicket from "../pages/SupportTicket";
import SubscriptionPlans from "../pages/SubscriptionPlans";
import SettingDetail from "../pages/SettingDetail";
import Analytics from "../pages/Analytics";

export const ProtectedRoutes = [
  {
    id: "1",
    path: dashboard,
    component: <Dashboard />,
  },
  {
    id: "2",
    path: analytics,
    component: <Analytics />,
  },
  {
    id: "3",
    path: settings,
    component: <Settings />,
  },
  {
    id: "4",
    path: supportTickets,
    component: <SupportTicket />,
  },
  {
    id: "5",
    path: users,
    component: <Users />,
  },
  {
    id: "6",
    path: payments,
    component: <Payments />,
  },
  {
    id: "7",
    path: subscriptionsPlans,
    component: <SubscriptionPlans />,
  },
  {
    id: "8",
    path: settingDetail,
    component: <SettingDetail />,
  },

];

export const PublicRoutes = [
  {
    id: "1",
    path: login,
    component: <Login />,
  },
  {
    id: "2",
    path: forgotPassword,
    component: <ForgotPassword />,
  },
  {
    id: "3",
    path: resetPassword,
    component: <ResetPassword />,
  },
  {
    id: "4",
    path: logout,
    component: <Navigate to={{ pathname: login }} />,
  },
  {
    id: "5",
    path: "/",
    component: <Navigate to={{ pathname: login }} />,
  },
];
