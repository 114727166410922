// import Select from "react-select";
// import React, { useState } from "react";

// const StatusFilter = ({ statusOption ,selected ,setSelected}) => {

//   const handleStatusOptionChange = (option) => {
//     setSelected(option);
//   };

//   const customStatusStyles = {
//     control: (provided, state) => ({
//       ...provided,
//       cursor: "pointer",
//       minHeight: "30px",
//       width: "100px",
//       borderColor: state.isFocused ? "none" : "none",
//       color: "#707070",
//       borderStyle: "none",
//     }),
//     option: (provided, state) => ({
//       ...provided,
//       cursor: "pointer",
//       fontSize: "13px",
//       color: state.isFocused ? "#4C51BF" : "black",
//       backgroundColor: state.isFocused ? "#E2E8F0" : "white",
//     }),
//     indicatorContainer: (provided) => ({
//       ...provided,
//       padding: "6px",
//     }),
//     indicatorSeparator: (provided) => ({
//       ...provided,
//       display: "none",
//     }),
//   };

//   return (
//     <div className="relative">
//       <Select
//         options={statusOption}
//         className="text-sm font-medium !text-primary-color-100"
//         styles={customStatusStyles}
//         value={selected}
//         onChange={handleStatusOptionChange}
//       />
//     </div>
//   );
// };

// export default StatusFilter;

import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { CheckIcon } from "@heroicons/react/20/solid";

export default function StatusFilter({
  options,
  selected,
  setSelected,
  className,
  settingsScreen,
  drawerFilter,
  isDisabled, // Add this prop
}) {
  return (
    <Listbox value={selected} onChange={setSelected} disabled={isDisabled}>
      <div
        className={`-mr-  relative ${
          settingsScreen ? "w-48 3xl:w-48" : drawerFilter ? "w-full" : "mt-1 w-32"
        }`}
      >
        <Listbox.Button
          className={`relative h-full w-full cursor-pointer rounded-full ${
            className ? className : "border"
          } border-primary-color-300 bg-white py-2.5 pl-4 pr-10 text-left text-sm focus:outline-none ${
            isDisabled
              ? "cursor-not-allowed bg-gray-200 text-gray-400 border-gray-300"
              : ""
          }`}
          disabled={isDisabled} // Disable the button
        >
          <span className={`block ${settingsScreen ? "pl-1" : "pl-6"} `}>
            {selected ? (
              selected.label
            ) : (
              <span className="font-normal text-primary-color-100">All</span>
            )}
          </span>
          <span className="absolute inset-y-0 flex items-center pr-2 pointer-events-none right-1">
            <ChevronDownIcon className="w-4 h-4 text-gray-400" aria-hidden="true" />
          </span>
        </Listbox.Button>

        {!isDisabled && (
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              className={`absolute z-10 mt-1 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ${
                settingsScreen ? "w-44" : "w-full"
              } max-h-60 ring-1 ring-black/5 focus:outline-none sm:text-sm`}
            >
              {options?.map((status, statusIdx) => (
                <Listbox.Option
                  key={statusIdx}
                  className={({ active }) =>
                    `relative z-10 cursor-pointer select-none py-2 pl-10 pr-4 ${
                      active
                        ? "bg-gray-200 text-secondary"
                        : "text-primary-color-100"
                    }`
                  }
                  value={status}
                >
                  {({ selected }) => (
                    <>
                      <span className={`block ${selected ? "font-medium" : "font-normal"}`}>
                        {status.label}
                      </span>
                      {selected && (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-accent">
                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        )}
      </div>
    </Listbox>
  );
}
