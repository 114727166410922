import moment from "moment";
import Datepicker from "react-tailwindcss-datepicker";
import React, { useState } from "react";
const DateRangePicker = ({ setRangePicker, width, rangePicker }) => {

    const [dateToShow, setDateToShow] = useState({
        startDate: null,
        endDate: null,
      });
    
  const handleValueChange = (newValue) => {
    const startDate = moment.utc(newValue.startDate);
    const endDate = moment.utc(newValue.endDate);

    const startDateUTCUnix = startDate.unix();
    const endDateUTCUnix = endDate.unix();

    setRangePicker({ startDate: startDateUTCUnix, endDate: endDateUTCUnix });

    setDateToShow(newValue)
  };

  return (
    <div className={`relative ${width || "w-40"}`}>
      <Datepicker
        value={dateToShow}
        onChange={handleValueChange}
        showShortcuts
        showFooter
        readOnly
        popoverDirection="down"
        inputClassName="w-full pr-8 cursor-pointer border-grayColor200 rounded-full focus:ring-0 text-sm placeholder:text-gray-400"
        placeholder="Select Date"
        displayFormat="ddd, MMM DD, YYYY"
      />
    </div>
  );
};

export default DateRangePicker;
