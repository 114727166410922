import moment from "moment";
import Lottie from "react-lottie";
import Tabs from "../components/Tabs";
import { Link } from "react-router-dom";
import ApiServices from "../api/services";
import { mediaUrl } from "../utils/config";
import Heading from "../components/Heading";
import Skeleton from "react-loading-skeleton";
import { images } from "../assets/styles/Images";
import { useMediaQuery } from "react-responsive";
import CardWidget from "../components/CardWidget";
import React, { useEffect, useState } from "react";
import RangePicker from "../components/RangePicker";
import DateRangePicker from "../components/DateRangePicker";
import FilterSelect from "../components/FilterSelect";
import { useLoggedContext } from "../context/provider";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { useSortableData } from "../hooks/useSortableData";
import animationData from "../assets/lotties/empty-folder.json";
import ConfirmationModal from "../components/ConfirmationModal";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

// Table Head
const TABLE_HEAD_PENDING = ["Setting Name", "Employees", "Created at", "Action"];
const TABLE_HEAD_PENDING_WithOutAction = ["Setting Name", "Employees", "Created at"];

const categories = [
  { id: "all", color: "#07966B", label: "All" },
  { id: "basic", color: "#FCEBB0", label: "Basic" },
  { id: "premium", color: "#B6E7CF", label: "Premium" },
  { id: "enterprise", color: "#FFBF9D", label: "Enterprise" },
  // { id: "ultra", color: "#264254", label: "Ultra" },
];

const tabs = [{ label: "Monthly" }, { label: "Yearly" }];

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const Dashboard = () => {
  // Context
  const { loading, setLoading, setBtnLoading, openSuccessModal, user, getPendingSettingsGlobal } = useLoggedContext();

  const todayStartDate = moment.utc().startOf("day");
  const todayEndDate = moment.utc().endOf("day");

  const [ticketData, setTicketData] = useState(null);
  const [overviewData, setOverviewData] = useState(null);
  const [activeTab, setActiveTab] = useState(tabs[0].label);
  const [activeCategory, setActiveCategory] = useState("All");
  const [subcritionsData, setSubcriptionData] = useState(null);
  const [overviewLoading, setOverViewLoading] = useState(false);
  const [barChartLoading, setBarChartLoading] = useState(false);
  const [subcritionsLoading, setSubcritionsLoading] = useState(false);
  const [supportTicketLoading, setSupportTicketLoading] = useState(false);
  const [pendingSettingsListing, setPendingSettingsListing] = useState([]);

  const [subscriptionSalesData, setSubscriptionSalesData] = useState(null);

  const [activeSubscriptionSalesTab, setActiveSubscriptionSalesTab] = useState(tabs[0].label);
  const [openAcceptDeclineModal, setOpenAcceptDeclineModal] = useState({ open: false, label: "", id: "" });

  // OverView Date Filter States
  const [dateRangeOverview, setDateRangeOverview] = useState({
    startDate: todayStartDate.unix(),
    endDate: todayEndDate.unix(),
  });

  // Subscription Date Filter States
  const [dateRangeSubscription, setDateRangeSubscription] = useState({
    startDate: null,
    endDate: null,
  });

  // Subscription Plans Date Filter States
  const [dateRangeSubPlans, setDateRangeSubPlans] = useState({
    startDate: todayStartDate.unix(),
    endDate: todayEndDate.unix(),
  });

  // Suppor Date Filter States
  const [dateRangeSupportTicket, setDateRangeSupportTicket] = useState({
    startDate: null,
    endDate: null,
  });

  // Support tickets data
  const opentTickets = ticketData ? parseFloat(ticketData.opent_tickets) : 0;
  const closedTickets = ticketData ? parseFloat(ticketData.closed_tickets) : 0;

  const dataPie2 = [
    {
      name: "Open",
      value: opentTickets !== 0 ? opentTickets : opentTickets == 0 && closedTickets == 0 ? 100 : 0,
      color: opentTickets !== 0 ? "#F9AE9C" : "#d1d5db",
    },
    {
      name: "Closed",
      value: closedTickets !== 0 ? closedTickets : 0,
      color: closedTickets !== 0 ? "#264254" : "#9ca3af",
    },
  ];

  // Subscription data
  const basicSubscription = subcritionsData ? parseFloat(subcritionsData.counts.Basic) : 0;
  const premiumSubscription = subcritionsData ? parseFloat(subcritionsData.counts.Premium) : 0;
  const enterpriseSubscription = subcritionsData ? parseFloat(subcritionsData.counts.Enterprise) : 0;
  const ultraSubscription = subcritionsData ? parseFloat(subcritionsData.counts.Ultra) : 0;

  const data2 = [
    {
      name: "Basic",
      value:
        basicSubscription !== 0
          ? basicSubscription
          : basicSubscription == 0 && premiumSubscription == 0 && enterpriseSubscription == 0 && ultraSubscription == 0
            ? 100
            : 0,
      color: basicSubscription !== 0 ? "#FCE2BF" : "#d1d5db",
    },
    {
      name: "Premium",
      value: premiumSubscription !== 0 ? premiumSubscription : 0,
      color: premiumSubscription !== 0 ? "#97F0C5" : "#d1d5db",
    },
    {
      name: "Enterprise",
      value: enterpriseSubscription !== 0 ? enterpriseSubscription : 0,
      color: enterpriseSubscription !== 0 ? "#FDAF9B" : "#d1d5db",
    },
    {
      name: "Ultra",
      value: ultraSubscription !== 0 ? ultraSubscription : 0,
      color: ultraSubscription !== 0 ? "#264254" : "#d1d5db",
    },
  ];

  // Initialize dataline with all months of the year
  const dataline = months?.map((month) => ({
    name: month,
    Basic: 0,
    Premium: 0,
    Enterprise: 0,
    // Ultra: 0,
  }));

  // Populate dataline with sales data
  subscriptionSalesData?.forEach((data) => {
    const date = new Date(data?.unix_created_at * 1000);
    const monthIndex = date?.getMonth();
    const plan = data?.plan_name;
    const price = parseFloat(data?.price);
    dataline[monthIndex][plan] += price;
  });

  // Graph Y Axis Format
  const formatYAxis = (value) => `£${value}`;

  // Graph Tooltip
  const formatTooltip = (value) => {
    if (!value) return value;

    const parts = value.toString().split(".");

    if (parts.length > 1 && parts[1].length > 2) {
      return value.toFixed(2);
    }

    return value;
  };

  // Handle Category Click
  const handleCategoryClick = (category) => {
    setActiveCategory(category);
  };

  // Pending Table Sorting
  const {
    items: pendingSettingList,
    requestSort: pendingSettingListSort,
    sortConfig: pendingSettingListSortConfig,
  } = useSortableData(pendingSettingsListing);

  // Media queries to detect screen size
  const isTablet = useMediaQuery({ maxWidth: 768 });
  const isLaptopMW = useMediaQuery({ maxWidth: 1024 });
  const isLaptop = useMediaQuery({ minWidth: 1024 });
  const isLaptopMedium = useMediaQuery({ minWidth: 1536 });
  const isLargeScreenLaptop = useMediaQuery({ minWidth: 1700 });

  // update items on different breakpoints
  const outerRadius = isLargeScreenLaptop ? 100 : isLaptopMedium ? 100 : isLaptop ? 100 : 100;
  const innerRadius = isLargeScreenLaptop ? 50 : isLaptopMedium ? 50 : isLaptop ? 60 : 60;

  // Custom Tooltip
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const name = payload[0].name;
      const value = payload[0].value;

      let color;

      const data2Item = data2.find((item) => item.name === name);
      if (data2Item) {
        color = data2Item.color;
      } else {
        const dataPieItem = dataPie2.find((item) => item.name === name);
        color = dataPieItem ? dataPieItem.color : "#000000";
      }

      return (
        <div className="relative p-4 bg-white rounded-20 shadow-card">
          <p className="label" style={{ color }}>{`${name} : ${value}`}</p>
        </div>
      );
    }
    return null;
  };

  // Get overview
  const getDashboardOveriew = () => {
    setOverViewLoading(true);
    let payload = {
      // start_date: dateRangeOverview.startDate ? dateRangeOverview.startDate : null,
      // end_date: dateRangeOverview.endDate ? dateRangeOverview.endDate : null,
    };

    ApiServices.dashboard
      .getOverview(payload)
      .then((res) => {
        const { data, message } = res;
        if (data.code === 200) {
          setOverviewData(data.data);
          setOverViewLoading(false);
        } else {
          setOverViewLoading(false);
        }
      })
      .catch((err) => {
        setOverViewLoading(false);
      });
  };

  // Get Support Ticket
  const getSupportTicket = () => {
    setSupportTicketLoading(true);

    let payload = {
      start_date: dateRangeSupportTicket.startDate ? dateRangeSupportTicket.startDate : null,
      end_date: dateRangeSupportTicket.endDate ? dateRangeSupportTicket.endDate : null,
    };

    ApiServices.dashboard
      .getDashboardSupportTicket(payload)
      .then((res) => {
        const { data, message } = res;
        if (data.code === 200) {
          setTicketData(data.data);
          setSupportTicketLoading(false);
        } else {
          setSupportTicketLoading(false);
        }
      })
      .catch((err) => {
        setSupportTicketLoading(false);
      });
  };

  // Get Subscription
  const getsubscription = () => {
    setSubcritionsLoading(true);

    let payload = {
      type: activeTab,
      start_date: dateRangeSubscription.startDate ? dateRangeSubscription.startDate : null,
      end_date: dateRangeSubscription.endDate ? dateRangeSubscription.endDate : null,
    };

    ApiServices.dashboard
      .getDashboardSubscription(payload)
      .then((res) => {
        const { data, message } = res;
        if (data.code === 200) {
          setSubcriptionData(data.data);
          setSubcritionsLoading(false);
        } else {
          setSubcritionsLoading(false);
        }
      })
      .catch((err) => {
        setSubcritionsLoading(false);
      });
  };

  // Get Subscription Sales
  const getsubscriptionSales = () => {
    setBarChartLoading(true);

    let payload;
    if (activeCategory === "All") {
      payload = {
        package: "",
        type: activeSubscriptionSalesTab,
        start_date: dateRangeSubPlans.startDate ? dateRangeSubPlans.startDate : null,
        end_date: dateRangeSubPlans.endDate ? dateRangeSubPlans.endDate : null,
      };
    } else {
      payload = {
        package: activeCategory,
        type: activeSubscriptionSalesTab,
        start_date: dateRangeSubPlans.startDate ? dateRangeSubPlans.startDate : null,
        end_date: dateRangeSubPlans.endDate ? dateRangeSubPlans.endDate : null,
      };
    }

    ApiServices.dashboard
      .getDashboardSubscriptionSales(payload)
      .then((res) => {
        const { data, message } = res;
        if (data.code === 200) {
          setSubscriptionSalesData(data?.data);
          setBarChartLoading(false);
        } else {
          setBarChartLoading(false);
        }
      })
      .catch((err) => {
        setBarChartLoading(false);
      });
  };

  // Get Pending Settings
  const getPendingSettings = () => {
    setLoading(true);
    let payload = {
      status: "Pending",
    };

    ApiServices.settings
      .getSettings(payload)
      .then((res) => {
        const { data, message } = res;

        if (data.code === 200) {
          setLoading(false);
          setPendingSettingsListing(data?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Accept & Decline Settings
  const acceptDeclineSetting = () => {
    setBtnLoading(true);
    let settingMessage = openAcceptDeclineModal.label === "Accept" ? "Nursery has been Accepted" : "Nursery has been Rejected";
    let settingStatus = openAcceptDeclineModal.label === "Accept" ? `Accept` : `Reject`;
    let settingId = openAcceptDeclineModal?.id;

    let formData = new FormData();
    formData.append("status", settingStatus);
    formData.append("id", settingId);

    ApiServices.settings
      .acceptDeclineSetting(formData)
      .then((res) => {
        const { data, message } = res;

        if (data.code === 200) {
          setBtnLoading(false);
          getPendingSettingsGlobal();
          getPendingSettings();
          setOpenAcceptDeclineModal({ open: false, label: "", id: "" });
          openSuccessModal({
            title: "Success!",
            message: settingMessage,
            onPress: (close) => {
              close();
              getPendingSettings();
              getPendingSettingsGlobal();
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Use Effects
  useEffect(() => {
    getDashboardOveriew();
  }, [dateRangeOverview]);

  useEffect(() => {
    getSupportTicket();
  }, [dateRangeSupportTicket]);

  useEffect(() => {
    getsubscription();
  }, [dateRangeSubscription, activeTab]);

  useEffect(() => {
    getsubscriptionSales();
  }, [activeCategory, activeSubscriptionSalesTab, dateRangeSubPlans]);

  useEffect(() => {
    getPendingSettings();
    getPendingSettingsGlobal();
  }, []);

  // Lottie Option
  const emptyFolderAnimation = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const hasPermissions = user?.permissions?.includes("super_panel_accept_decline_request") || user?.role === "super_admin";

  return (
    <>
      <div className="grid grid-cols-12 gap-5 ">
        {(user?.permissions?.includes("super_panel_overview") || user?.role === "super_admin") && (
          <div className="col-span-7 space-y-8 card md:col-span-12 xl:col-span-6 2xl:col-span-7">
            <div className="flex ">
              <Heading
                heading="Overview"
                subheading="Key highlights and essential data."
                icon
                handleRefresh={() => {
                  getDashboardOveriew();
                }}
              />
              {/* <RangePicker setRangePicker={setDateRangeOverview} width="xl:w-80"/> */}
            </div>

            {overviewLoading ? (
              <div className="grid grid-cols-4 gap-4">
                {[...Array(4)].map((_, index) => (
                  <Skeleton key={index} count={1} height={180} borderRadius={16} />
                ))}
              </div>
            ) : (
              <>
                <div className="grid grid-cols-4 gap-4 ">
                  <CardWidget
                    image={images.TotalSettings}
                    title={overviewData?.settingCount}
                    subtitle="Total Settings"
                    background="bg-lightYellowColor hover:border-darkYellowColor hover:shadow-orange-card"
                    arrowText="+00.00"
                  />
                  <CardWidget
                    image={images.TotalTickets}
                    title={overviewData?.ticketCount}
                    subtitle="Total Tickets"
                    background="bg-lightEmeraldColor/20 hover:border-darkEmeraldColor hover:shadow-green-card"
                    arrowText="+00.00"
                    // arrowColor="text-red-500"
                    // arrowImage={images.ArrowDown}
                  />
                  <CardWidget
                    image={images.TotalUsers}
                    title={overviewData?.userCount}
                    subtitle="Total Users"
                    background="bg-lightRedColor/20 hover:border-darkRedColor hover:shadow-light-red-card"
                    arrowText="+00.00"
                  />
                  <CardWidget
                    image={images.TotalEarnings}
                    title={`£${
                      overviewData?.totalEarning && overviewData?.totalEarning % 1 !== 0
                        ? Number(overviewData?.totalEarning).toFixed(2)
                        : overviewData?.totalEarning
                    }`}
                    subtitle="Total Earning"
                    arrowText="+00.00"
                  />
                </div>
              </>
            )}
          </div>
        )}

        {(user?.permissions?.includes("super_panel_subscription_analytics") || user?.role === "super_admin") && (
          <div className="col-span-5 space-y-5 card md:col-span-12 xl:col-span-6 2xl:col-span-5">
            <div className="flex justify-between">
              <Heading
                heading="Subscriptions"
                subheading="Key highlights and sales of subcritions"
                icon
                handleRefresh={() => {
                  getsubscription();
                }}
              />

              {/* <RangePicker setRangePicker={setDateRangeSubscription} width="xl:w-80" /> */}
              <DateRangePicker setRangePicker={setDateRangeSubscription} rangePicker={dateRangeSubscription} width="xl:w-80" />
              
            </div>

            {subcritionsLoading ? (
              <div className="grid grid-cols-2 gap-4">
                {[...Array(2)].map((_, index) => (
                  <Skeleton key={index} count={1} height={190} borderRadius={16} />
                ))}
              </div>
            ) : (
              <>
                <div className="flex justify-evenly">
                  <div>
                    <ResponsiveContainer width={220} height={200}>
                      <PieChart
                        margin={{
                          top: 5,
                          right: 5,
                          bottom: 5,
                          left: 0,
                        }}
                      >
                        <Pie
                          margin={{
                            top: 5,
                            right: 5,
                            bottom: 5,
                            left: 0,
                          }}
                          isAnimationActive={false}
                          data={data2}
                          height={220}
                          width={300}
                          cx="50%"
                          cy="50%"
                          innerRadius={innerRadius}
                          outerRadius={outerRadius}
                          fill="#d6d6d6"
                          dataKey="value"
                        >
                          {data2.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} stroke="none" className="border-none outline-none" />
                          ))}
                        </Pie>

                        {
                          subcritionsData && // Checks if subcritionsData exists and is not null or undefined
                            ((parseFloat(subcritionsData.counts.Basic) !== 0 && <Tooltip content={<CustomTooltip />} />) ||
                              (parseFloat(subcritionsData.counts.Premium) !== 0 && <Tooltip content={<CustomTooltip />} />) ||
                              (parseFloat(subcritionsData.counts.Enterprise) !== 0 && <Tooltip content={<CustomTooltip />} />))

                          // (parseFloat(subcritionsData.counts.Ultra) !== 0 && <Tooltip content={<CustomTooltip />} />))
                        }
                      </PieChart>
                    </ResponsiveContainer>
                  </div>

                  <div className="space-y-5">
                    <div className="flex justify-center">
                      <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
                    </div>
                    {/* Percentage data */}

                    <div className="flex flex-col">
                      <div className="flex items-center">
                        <div className="mr-3 block h-3.5 w-3.5 shrink-0 rounded-full bg-[#FCE2BF] lg:mr-3 2xl:mr-4 2xl:h-4 2xl:w-4"></div>
                        <div className="">
                          <span className="text-base text-grayColor300 ">Basic</span>
                          <span className="text-black opacity-30"> ---------- </span>
                          <span className="text-sm font-medium text-secondary">
                            {" "}
                            {subcritionsData?.percentages.Basic ? <>{subcritionsData?.percentages.Basic}</> : "0%"}
                          </span>{" "}
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div className="mr-3 block h-3.5 w-3.5 shrink-0 rounded-full bg-[#97F0C5] lg:mr-3 2xl:mr-4 2xl:h-4 2xl:w-4"></div>
                        <div className="">
                          <span className="text-base text-grayColor300 ">Premium</span>
                          <span className="text-black opacity-30"> ------- </span>
                          <span className="text-sm font-medium text-secondary">
                            {subcritionsData?.percentages.Premium ? <>{subcritionsData?.percentages.Premium}</> : "0%"}
                          </span>{" "}
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div className="mr-3 block h-3.5 w-3.5 shrink-0 rounded-full bg-[#FDAF9B] lg:mr-3 2xl:mr-4 2xl:h-4 2xl:w-4"></div>
                        <div className="">
                          <span className="text-base text-grayColor300 ">Enterprise</span>
                          <span className="text-black opacity-30"> ------ </span>
                          <span className="text-sm font-medium text-secondary">
                            {subcritionsData?.percentages.Enterprise ? <>{subcritionsData?.percentages.Enterprise}</> : "0%"}
                          </span>{" "}
                        </div>
                      </div>
                      {/* <div className="flex items-center">
                        <div className="mr-3 block h-3.5 w-3.5 shrink-0 rounded-full bg-[#264254] lg:mr-3 2xl:mr-4 2xl:h-4 2xl:w-4"></div>
                        <div className="">
                          <span className="text-base text-grayColor300 ">Ultra</span>
                          <span className="text-black opacity-30"> ------------ </span>
                          <span className="text-sm font-medium text-secondary">
                            {subcritionsData?.percentages.Ultra ? <>{subcritionsData?.percentages.Ultra}</> : "0%"}
                          </span>{" "}
                        </div>
                      </div> */}
                    </div>
                    {/* Percentage data */}
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>

      {/* table component */}
      <div className="grid grid-cols-12 gap-5 mt-5">
        {(user?.permissions?.includes("super_panel_ticket_analystics") || user?.role === "super_admin") && (
          <div className="col-span-5 space-y-10 card md:col-span-12 xl:col-span-6 2xl:col-span-5">
            <div className="flex justify-between">
              <Heading
                heading="Support Tickets"
                subheading="Highlights of open & closed tickets"
                icon
                handleRefresh={() => {
                  getSupportTicket();
                }}
              />
              {/* <RangePicker setRangePicker={setDateRangeSupportTicket} width="2xl:w-80 xl:w-76" /> */}
              <DateRangePicker setRangePicker={setDateRangeSupportTicket} rangePicker={dateRangeSupportTicket} width="2xl:w-80 xl:w-80" />
            </div>

            {supportTicketLoading ? (
              <div className="grid grid-cols-2 gap-4">
                {[...Array(2)].map((_, index) => (
                  <Skeleton key={index} count={1} height={180} borderRadius={16} />
                ))}
              </div>
            ) : (
              <>
                <div className="flex justify-evenly">
                  <div>
                    <ResponsiveContainer width={220} height={200}>
                      <PieChart margin={{ top: 5, right: 5, bottom: 5, left: 0 }}>
                        <Pie data={dataPie2} cx="50%" cy="50%" innerRadius={innerRadius} outerRadius={outerRadius} fill="#d6d6d6" dataKey="value">
                          {dataPie2.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} stroke="none" className="border-none outline-none" />
                          ))}
                        </Pie>
                        {ticketData && ( // Checks if ticketData exists and is not null or undefined
                          (parseFloat(ticketData.open_tickets) !== 0 && <Tooltip content={<CustomTooltip />} />) ||
                          parseFloat(ticketData.closed_tickets) !== 0 && <Tooltip content={<CustomTooltip />} />)}
                      </PieChart>
                    </ResponsiveContainer>
                  </div>

                  <div className="flex flex-col justify-center">
                    <div className="flex items-center ">
                      <div className="mr-3 block h-3.5 w-3.5 shrink-0 rounded-full bg-[#FFBF9D] lg:mr-3 2xl:mr-4 2xl:h-4 2xl:w-4"></div>
                      <div className="">
                        <span className="text-base text-grayColor300 ">Open</span>
                        <span className="text-black opacity-30"> ---------- </span>
                        <span className="text-sm font-medium text-secondary">{ticketData ? ticketData.open_percentage : "0%"}</span>{" "}
                      </div>
                    </div>
                    <div className="flex items-center ">
                      <div className="mr-3 block h-3.5 w-3.5 shrink-0 rounded-full bg-[#264254] lg:mr-3 2xl:mr-4 2xl:h-4 2xl:w-4"></div>
                      <div className="">
                        <span className="text-base text-grayColor300 ">Closed</span>
                        <span className="text-black opacity-30"> -------- </span>
                        <span className="text-sm font-medium text-secondary">{ticketData ? ticketData.closed_percentage : "0%"}</span>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}

        <div className="col-span-7 card md:col-span-12 xl:col-span-6 2xl:col-span-7">
          <div className="flex justify-between">
            <div className="flex">
              <Heading
                heading="Recent Requests"
                subheading="Accept or decline & see details by click on anyone."
                icon
                handleRefresh={() => {
                  getPendingSettings();
                }}
              />
            </div>
            <Link
              to={"/settings"}
              className="relative shrink-0 text-base font-medium text-secondary before:absolute before:bottom-8 before:h-0.5 before:w-full before:rounded before:bg-secondary before:content-['']"
            >
              View All
            </Link>
          </div>

          <div className="mb-8 -mx-6 overflow-x-auto">
            <table className="w-full text-left">
              <thead>
                <tr>
                  {(hasPermissions ? TABLE_HEAD_PENDING : TABLE_HEAD_PENDING_WithOutAction).map((head) => (
                    <th
                      key={head}
                      className="p-4 bg-white border-b border-gray-100 first:pl-6"
                      onClick={() => {
                        let sortKey;
                        if (head === "Setting Name") {
                          sortKey = "name";
                        } else if (head === "Contact Person") {
                          sortKey = "contact_person_name";
                        } else if (head === "Created at") {
                          sortKey = "created_at";
                        } else {
                          sortKey = head.toLowerCase();
                        }
                        pendingSettingListSort(sortKey);
                      }}
                    >
                      <p className="text-xs font-semibold leading-5 cursor-pointer whitespace-nowrap font-inter 3xl:text-sm">
                        {head}
                        {pendingSettingListSortConfig.key ===
                          (head === "Setting Name"
                            ? "name"
                            : head === "Contact Person"
                              ? "contact_person_name"
                              : head === "Created at"
                                ? "created_at"
                                : head.toLowerCase()) && pendingSettingListSortConfig.direction === "asc" ? (
                          <>{head === "Action" ? "" : <ChevronUpIcon className="inline-block h-4 w-3.5" />}</>
                        ) : (
                          <>{head === "Action" ? "" : <ChevronDownIcon className="inline-block h-4 w-3.5" />}</>
                        )}
                      </p>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="6">
                      <Skeleton count={5} height={50} />
                    </td>
                  </tr>
                ) : pendingSettingList.length > 0 ? (
                  pendingSettingList.slice(0, 3).map((item, index) => (
                    <tr key={item?.id} className="transition-all duration-300 even:bg-gray-50 hover:bg-secondary/10">
                      <td className="py-3 pl-4 3xl:px-4 3xl:first:pl-6">
                        <div className="flex items-center">
                          <div className="w-8 h-8 mr-3 rounded-full">
                            <img className="w-8 h-8 mr-3 rounded-full" src={item?.logo ? mediaUrl + item?.logo : images.placeholder} alt="Profile" />
                          </div>
                          <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.name}</p>
                        </div>
                      </td>
                      {/* <td className="py-3 pl-4 3xl:px-4">
                        <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.contact_person_name}</p>
                      </td> */}
                      <td className="py-3 pl-4 pr-3 3xl:px-4">
                        <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.users_count}</p>
                      </td>

                      <td className="py-3 pl-4 pr-3 3xl:px-4">
                        <p id={`date${index + 1}`} className="text-xs font-normal text-primary-color-200 3xl:text-sm">
                          {moment.unix(item?.created_at).format("D MMM YYYY")}
                        </p>
                        <ReactTooltip
                          anchorSelect={`#date${index + 1}`}
                          id={`date-tooltip${index + 1}`}
                          offset={5}
                          className="border border-gray-100 !bg-white !text-primary-color-100 !opacity-100 shadow-card"
                          classNameArrow="hidden"
                          effect="solid"
                          place="bottom"
                        >
                          {/* Tooltip Content */}
                          <span>{moment.unix(item?.created_at).format("D MMM YYYY h:mm A")}</span>
                        </ReactTooltip>
                      </td>
                      {(user?.permissions?.includes("super_panel_accept_decline_request") || user?.role === "super_admin") && (
                        <td className="py-3 pl-4 pr-3 3xl:px-4">
                          <div className="flex items-center gap-x-3">
                            <span
                              onClick={() => setOpenAcceptDeclineModal({ open: true, label: "Accept", id: item?.user?.id })}
                              className="text-xs underline cursor-pointer text-accent 3xl:text-sm"
                            >
                              Accept
                            </span>
                            <span
                              onClick={() => setOpenAcceptDeclineModal({ open: true, label: "Decline", id: item?.user?.id })}
                              className="text-xs text-red-500 underline cursor-pointer 3xl:text-sm"
                            >
                              Decline
                            </span>
                          </div>
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr className=" h-[150px]">
                    <td colSpan="6">
                      <Lottie options={emptyFolderAnimation} width={200} height={200} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* table component */}

      {/* line chart  */}

      {(user?.permissions?.includes("super_panel_subscription_sales") || user?.role === "super_admin") && (
        <div className="w-full mt-5 space-y-10 card">
          <div className="flex justify-between">
            <Heading
              heading="Subscription Plans"
              subheading="Highlights of subscriptions plans sales."
              icon
              handleRefresh={() => {
                getsubscriptionSales();
              }}
            />

            {!isLaptopMW && (
              <div className="flex">
                {categories.map((category) => (
                  <div
                    key={category.id}
                    className="flex items-center justify-center cursor-pointer"
                    onClick={() => handleCategoryClick(category.label)}
                  >
                    <div
                      className={`ml-3 mr-3 block h-3.5 w-3.5 shrink-0 rounded-full bg-${category.color} lg:mr-3 2xl:mr-2 2xl:h-4 2xl:w-4`}
                      style={{ backgroundColor: category.color }}
                    ></div>
                    <span
                      className={`${
                        activeCategory === category.label
                          ? "border-b-2 border-grayColor300 text-base font-semibold  text-grayColor300"
                          : "text-base text-grayColor300"
                      }`}
                    >
                      {category.label}
                    </span>
                  </div>
                ))}
              </div>
            )}

            <div className="flex">
              <Tabs tabs={tabs} setActiveTab={setActiveSubscriptionSalesTab} activeTab={activeSubscriptionSalesTab} />
            </div>

            <RangePicker setRangePicker={setDateRangeSubPlans} width="2xl:w-80 xl:w-60" />
          </div>

          {isLaptopMW && (
            <div className="flex">
              {categories.map((category) => (
                <div
                  key={category.id}
                  className="flex items-center justify-center cursor-pointer"
                  onClick={() => handleCategoryClick(category.label)}
                >
                  <div
                    className={`ml-3 mr-3 block h-3.5 w-3.5 shrink-0 rounded-full bg-${category.color} lg:mr-3 2xl:mr-2 2xl:h-4 2xl:w-4`}
                    style={{ backgroundColor: category.color }}
                  ></div>
                  <span
                    className={`${
                      activeCategory === category.label ? "border-b-2 text-base font-semibold  text-grayColor300" : "text-base text-grayColor300"
                    }`}
                  >
                    {category.label}
                  </span>
                </div>
              ))}
            </div>
          )}

          {barChartLoading ? (
            <div className="grid grid-cols-1 gap-4">
              {[...Array(1)].map((_, index) => (
                <Skeleton key={index} count={1} height={350} borderRadius={16} />
              ))}
            </div>
          ) : (
            <>
              <div>
                <ResponsiveContainer width="100%" height={350}>
                  <LineChart
                    width={500}
                    height={300}
                    data={dataline}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" vertical={false} />
                    <XAxis dataKey="name" tickLine={false} />
                    <YAxis tickLine={false} tickFormatter={formatYAxis} />
                    <Tooltip formatter={formatTooltip} />
                    {/* <Legend /> */}
                    <Line type="monotone" dataKey="Basic" strokeWidth={2} dot={false} stroke="#FCE2BF" activeDot={{ r: 6 }} />
                    <Line type="monotone" dataKey="Premium" strokeWidth={2} dot={false} stroke="#97F0C5" activeDot={{ r: 6 }} />
                    <Line type="monotone" dataKey="Enterprise" strokeWidth={2} dot={false} stroke="#FDAF9B" activeDot={{ r: 6 }} />
                    {/* <Line type="monotone" dataKey="Ultra" strokeWidth={2} dot={false} stroke="#264254" activeDot={{ r: 6 }} /> */}
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </>
          )}
        </div>
      )}

      {/* line chart  */}

      {/* Accept Decline Modal */}
      <ConfirmationModal
        isOpen={openAcceptDeclineModal.open}
        label={openAcceptDeclineModal.label}
        handleSubmit={acceptDeclineSetting}
        setIsOpen={(open) => setOpenAcceptDeclineModal((prev) => ({ ...prev, open }))}
      />
    </>
  );
};

export default Dashboard;
