import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Select from "react-select";
import { toUTCUnixTimestamp } from "../helperFunctions/helper";

const FilterSelect = ({ filterStartDate, setFilterStartDate, filterEndDate, setFilterEndDate }) => {
  const options = [
    {
      value: {
        start: moment().utc().startOf("day").unix(),
        end: moment().utc().endOf("day").unix(),
      },
      label: "Today",
    },
    {
      value: {
        start: moment().subtract(1, "day").utc().startOf("day").unix(),
        end: moment().subtract(1, "day").utc().endOf("day").unix(),
      },
      label: "Yesterday",
    },
    {
      value: {
        start: moment().subtract(1, "week").startOf("week").utc().startOf("day").unix(),
        end: moment().subtract(1, "week").endOf("week").utc().endOf("day").unix(),
      },
      label: "Last Week",
    },
    {
      value: {
        start: moment().subtract(1, "month").startOf("month").utc().startOf("day").unix(),
        end: moment().subtract(1, "month").endOf("month").utc().endOf("day").unix(),
      },
      label: "Last Month",
    },
    {
      value: {
        start: moment().subtract(6, "month").startOf("month").utc().startOf("day").unix(),
        end: moment().subtract(1, "month").endOf("month").utc().endOf("day").unix(),
      },
      label: "Last Six Months",
    },
    {
      value: {
        start: moment().subtract(1, "year").startOf("year").utc().startOf("day").unix(),
        end: moment().subtract(1, "year").endOf("year").utc().endOf("day").unix(),
      },
      label: "Last Year",
    },
    { value: "custom", label: "Custom" },
  ];

  const [showDatePickerFilter, setShowDatePickerFilter] = useState(false);
  const [selectedFilterOption, setSelectedFilterOption] = useState(options[2]);

  const handleCustomDateChange = (dates) => {
    const [start, end] = dates;
    setFilterStartDate(toUTCUnixTimestamp(start));
    setFilterEndDate(toUTCUnixTimestamp(end));

    const selectedDate = moment(start).format("DD-MM-YYYY");
    setSelectedFilterOption({
      value: {
        start: moment(start).startOf("day").unix(),
        end: moment(end).endOf("day").unix(),
      },
      label: selectedDate,
    });

    setShowDatePickerFilter(!(start && end));
  };

  const handleOptionChange = (option) => {
    if (option.value === "custom") {
      setShowDatePickerFilter(true);
    } else {
      setShowDatePickerFilter(false);
      setFilterStartDate(toUTCUnixTimestamp(option.value.start));
      setFilterEndDate(toUTCUnixTimestamp(option.value.end));
    }
    setSelectedFilterOption(option);
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      cursor: "pointer",
      minHeight: "30px",
      borderRadius: "50px",
      width: "130px",
      borderColor: state.isFocused ? "#4C51BF" : "#E2E8F0",
      boxShadow: state.isFocused ? "0 0 0 3px rgba(76, 81, 191, 0.3)" : "none",
      "&:hover": {
        borderColor: state.isFocused ? "#4C51BF" : "#CBD5E0",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      cursor: "pointer",
      fontSize: "13px",
      color: state.isFocused ? "#4C51BF" : "black",
      backgroundColor: state.isFocused ? "#E2E8F0" : "white",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      padding: "6px",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
  };

  useEffect(() => {
    setFilterStartDate(toUTCUnixTimestamp(selectedFilterOption.value.start));
    setFilterEndDate(toUTCUnixTimestamp(selectedFilterOption.value.end));
  }, []);

  return (
    <div className="relative">
      <Select
        options={options}
        className="text-xs"
        styles={customStyles}
        value={selectedFilterOption}
        onChange={handleOptionChange}
        isSearchable={false}
      />

      <div className="absolute right-5 z-10">
        {showDatePickerFilter && (
          <DatePicker
            selected={new Date()}
            onChange={(dates) => handleCustomDateChange(dates)}
            startDate={filterStartDate}
            endDate={filterEndDate}
            selectsRange
            inline
          />
        )}
      </div>
    </div>
  );
};

export default FilterSelect;
