// ===================== Login Routes ========================== //
export const login = "login".toLowerCase();

export const forgotPassword = "forgot-password".toLowerCase();
export const resetPassword = "reset-password/:token".toLowerCase();

export const logout = "login".toLowerCase();

// ===================== Dashboard Routes ========================== //

export const dashboard = "dashboard".toLowerCase();
export const settings = "settings".toLowerCase();
export const supportTickets = "support-tickets".toLowerCase();
export const users = "users".toLowerCase();
export const payments = "payments".toLowerCase();
export const settingDetail = "settings/:id".toLocaleLowerCase();
export const subscriptionsPlans = "subscriptions-plans".toLocaleLowerCase();
export const analytics = "analytics".toLocaleLowerCase();
