import React, { useState } from "react";
import ApiServices from "../api/services";
import Spinner from "../components/Spinner";
import { login } from "../routes/routesDefined";
import { images } from "../assets/styles/Images";
import { Link, useNavigate } from "react-router-dom";
import { useLoggedContext } from "../context/provider";

const ForgotPassword = () => {
  let navigate = useNavigate();

  const { setBtnLoading, openSuccessModal } = useLoggedContext();

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = { email: email };

    ApiServices.auth
      .forgotPassword(payload)
      .then((response) => {
        if (response.data.code === 200) {
          setLoading(false);
          openSuccessModal({
            title: "Success!",
            message: response.data.message,
            onPress: (close) => {
              close();
              navigate("/" + login, { replace: false });
            },
          });
          setEmail("");
        } else {
          setLoading(false);
          setError("Invalid User");
        }
      })
      .catch((error) => {
        setError(error.response.data.message);
        setLoading(false);
      });
  };

  const handleForgetInput = (e) => {
    setEmail(e.target.value);
    setError("");
  };

  return (
    <>
      <div className="relative flex  h-screen overflow-hidden bg-white">
        <div className="h-screen w-full bg-white px-6 sm:px-8 md:px-16 lg:w-1/2">
          <div className="sm:w-[70% mx-auto flex h-full w-full flex-col justify-center 2xl:w-[60%]">
            <img src={images.NewLogo} alt="edufeel Social Logo" className="mx-auto mb-8 w-[15rem] lg:mx-0 2xl:w-[19.4rem]" />

            <div>
              <div className="text-grayColor500 mb-2 text-center text-lg font-semibold leading-tight lg:text-start 2xl:text-xl">
                Forgot your password?
              </div>
              <p className="text-md mb-8 text-center font-normal leading-normal text-grayColor300 lg:mb-10 lg:text-start lg:leading-snug 2xl:mb-12">
                Enter your email below and receive a password reset link.
              </p>
            </div>
            <form onSubmit={handleFormSubmit} className="mx-auto w-full">
              <div>
                <input
                  value={email}
                  onChange={handleForgetInput}
                  type="email"
                  className={`text-md text-grayColor500 mb-3 w-full rounded-full border border-grayColor100 p-3 px-5 leading-normal outline-none transition duration-150 ease-in-out md:p-3.5 md:px-6 lg:text-base 2xl:leading-loose`}
                  id="email"
                  placeholder="Email Address"
                  required
                  autoComplete="off"
                />

                {error.length >= 1 && <span className="mb-8 pl-2 text-red-500">{error}</span>}
              </div>
              <div className="text-right">
                {loading ? (
                  <p className="forgot-password text-base font-medium text-gray-500 underline lg:text-base 2xl:text-lg">Back to Login?</p>
                ) : (
                  <Link className="forgot-password text-grayColor500 font-medium underline" to={"/" + login}>
                    Back to Login?
                  </Link>
                )}
              </div>

              <div className="mt-5 flex items-center justify-center lg:mt-6 2xl:mt-10">
                <button
                  className={`w-full rounded-full bg-secondary py-4 px-4 text-base font-medium leading-normal text-white shadow-md xl:text-lg 2xl:leading-loose `}
                >
                  {loading ? <Spinner /> : "Send"}
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="hidden h-screen w-full bg-white sm:p-8 lg:block lg:w-1/2 lg:p-8 2xl:p-16">
          <img src={images.authImgSvg} className="ml-auto h-full w-full rounded-3xl object-cover" style={{ objectPosition: "50% 5%" }} alt="member" />
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
