import Spinner from "./Spinner";
import { Fragment } from "react";
import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Dialog, Transition } from "@headlessui/react";
import { useLoggedContext } from "../context/provider";
import { XMarkIcon } from "@heroicons/react/20/solid";
import ApiServices from "../api/services";
import { toUTCUnixTimestamp } from "../helperFunctions/helper";
export default function UpdateTrialModal({ isOpen, setIsOpen, subscriptionPlanFreetrial, onSuccess, userId, freeTrialDay, setFreeTrialDay }) {
  // Context
  const { btnLoading, setBtnLoading, openSuccessModal } = useLoggedContext();

  const backgroundColor = "secondary";

  const closeModal = () => {
    setMessage("");
    setIsOpen(false);
    setTrialDate("");
  };

  const [message, setMessage] = useState("");
  const [trialDate, setTrialDate] = useState("");
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const getButtonStyles = (buttonType) => {
    const baseStyles =
      "inline-flex w-full justify-center rounded-full px-4 py-2.5 text-base font-medium transition-all duration-300 focus:outline-none";
    switch (buttonType) {
      case "Yes":
        return `bg-${backgroundColor} text-white ${baseStyles} ${btnLoading ? "" : "hover:opacity-75"}`;
      case "No":
        return `border border-${backgroundColor} text-${backgroundColor} hover:bg-${backgroundColor} ${baseStyles} hover:text-white`;
      default:
        return "";
    }
  };

  // handle Check in form date
  const handleDateChange = (date) => {
    // const unixTimestamp = date.getTime();
    setTrialDate(date);
    setMessage("");
  };

  const handleUpdateTrial = () => {
    if (subscriptionPlanFreetrial) {
      if (!freeTrialDay) {
        setMessage("Day is required");
        return;
      } else {
        setBtnLoading(true);
        const payload = new FormData();
        payload.append("free_trial_days", freeTrialDay);
        ApiServices.subscriptionPlans
          .updateSubscriptionFreeTrial(payload)
          .then((res) => {
            if (res?.data?.code === 200) {
              setBtnLoading(false);
              closeModal();
              openSuccessModal({
                title: "Success!",
                message: "Free Trial Days Updated Successfully",
                onPress: (close) => {
                  close();
                  onSuccess();
                },
              });
              setTrialDate(null);
            }
            setBtnLoading(false);
          })
          .catch(({ response }) => {
            console.log({ response });
            setBtnLoading(false);
          });
      }
    } else {
      if (!trialDate) {
        setMessage("Date is required");
        return;
      } else {
        setBtnLoading(true);
        const payload = new FormData();
        payload.append("user_id", userId);
        payload.append("end_date", toUTCUnixTimestamp(trialDate));
        ApiServices.subscriptionPlans
          .updateSubscriptionFreeTrialDate(payload)
          .then((res) => {
            if (res?.data?.code === 200) {
              setBtnLoading(false);
              closeModal();
              openSuccessModal({
                title: "Success!",
                message: "Free Trial Date Updated Successfully",
                onPress: (close) => {
                  close();
                  onSuccess();
                },
              });
              setTrialDate(null);
            }
            setBtnLoading(false);
          })
          .catch(({ response }) => {
            console.log({ response });
            setMessage(response?.data?.message);
            setBtnLoading(false);
          });
      }
    }
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-75"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-75"
              >
                <Dialog.Panel className="w-full max-w-md p-8 overflow-hidden text-center align-middle transition-all bg-white shadow-xl rounded-2xl">
                  <div className="flex items-center justify-between">
                    <h2 className="text-lg font-medium text-secondary-color">
                      {subscriptionPlanFreetrial ? "Edit Free Trial" : "Update Trial Period"}
                    </h2>
                    <div onClick={closeModal}>
                      <XMarkIcon className="cursor-pointer h-7 w-7 text-secondary-color" />
                    </div>
                  </div>
                  <div className="mt-6 space-y-2 ">
                    <h2 className="pl-2 text-sm font-medium text-left text-primary-color-400">
                      {" "}
                      {subscriptionPlanFreetrial ? "Free Trial Days" : "Keep trial until"}
                    </h2>
                    {subscriptionPlanFreetrial ? (
                      <div>
                        <input
                          className="placeholder:[#C5C5C5] w-full rounded-full border-[1px]  border-[#C5C5C5] px-6 py-3 text-sm font-normal text-[#474747]"
                          placeholder="Enter free trial days"
                          type="number"
                          value={freeTrialDay}
                          onChange={(e) => {
                            const enteredValue = e.target.value;
                            if (/^\d*$/.test(enteredValue)) {
                              setFreeTrialDay(enteredValue);
                              setMessage("");
                            }
                          }}
                        />
                      </div>
                    ) : (
                      <div onClick={() => setIsDatePickerOpen(true)}>
                        <ReactDatePicker
                          selected={trialDate}
                          onChange={handleDateChange}
                          open={isDatePickerOpen}
                          onClickOutside={() => setIsDatePickerOpen(false)}
                          dateFormat="P"
                          placeholderText="Select Date"
                          minDate={new Date()}
                          className="w-full rounded-full"
                          readOnly
                        />
                      </div>
                    )}
                    <div className="px-2 mt-4 text-left">
                      <p className="text-sm text-red-500">{message}</p>
                    </div>
                  </div>

                  <div className="flex mt-24 gap-x-6">
                    <button type="button" className={getButtonStyles("Yes")} onClick={handleUpdateTrial || closeModal}>
                      {btnLoading ? <Spinner /> : "Update"}
                    </button>

                    <button type="button" className={getButtonStyles("No")} onClick={closeModal}>
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
