import React from "react";

const NotificationCircle = ({ notificationCount }) => {
  return (
    <div className="h-5 w-5 rounded-full bg-red-500 flex items-center justify-center ">
      <h2 className="text-xs  text-white">{notificationCount}</h2>
    </div>
  );
};

export default NotificationCircle;
