import React from "react";
import { images } from "../assets/styles/Images";

const TitleValue = ({ title, value, lastUpdated }) => {
  return (
    <div>
      <h3 className="text-[10px] text-primary-color-100 2xl:text-xs">{title}</h3>
      <h2 className="flex-wrap break-all text-xs font-medium text-secondary-color 2xl:text-sm">
        {value} {lastUpdated && value === null && <img src={images.info} alt="info" className="inline-block" />}
      </h2>
    </div>
  );
};

export default TitleValue;
