import Camera from "../img/camera.png";
import NewLogo from "../img/new-logo.svg";
import logo from "../img/workwell-logo.svg";
import authImgSvg from "../img/auth-image.webp";
import placeholder from "../img/placeholder.svg";
import logout from "../img/icons/signout-icon.svg";
import GreatEmoji from "../img/icons/great-emoji.svg";
import QuestionRed from "../img/icons/question-red.svg";
import QuestionBlue from "../img/icons/question-blue.svg";
import QuestionGreen from "../img/icons/question-green.svg";

// sidebar
import Users_Icon from "../img/icons/users-icon.svg";
import Users_icon from "../img/icons/users-icon.svg";
import Payments_Icon from "../img/icons/payments.svg";
import Settings_Icon from "../img/icons/settings.svg";
import Analytics_Icon from "../img/icons/analytics.svg";
import Dashboard_Icon from "../img/icons/dashboard-icon.svg";
import Subscription_Icon from "../img/icons/subscription.svg";
import SupportTicket_Icon from "../img/icons/support-tickets.svg";
import Notification_Icon from "../img/icons/notifications-icon.svg";

// Card Widget
import ArrowDown from "../img/icons/down.svg";
import ArrowRise from "../img/icons/arrow-rise.svg";
import PaymentCardIcon from "../img/icons/card-icon.svg";
import PaymentCardIcon2 from "../img/icons/card-icon2.svg";

// settings
import Recipt from "../img/recipt.png";
import info from "../img/icons/info.svg";
import Setting1 from "../img/setting1.webp";
import Setting2 from "../img/setting2.webp";

// support Tickets
import Open from "../img/icons/Open.svg";
import Crown from "../img/icons/crown.svg";
import Closed from "../img/icons/Closed.svg";
import Tickets from "../img/icons/Tickets.svg";
import Comment from "../img/icons/Comment.svg";
import TicketDetail from "../img/ticketDetail.png";
import ActiveOpen from "../img/icons/ActiveOpen.svg";
import Attachments from "../img/icons/attachment.svg";
import CompanyIcon from "../img/icons/CompanyIcon.svg";
import ActiveClosed from "../img/icons/ActiveClosed.svg";
import OpenTicketIcon from "../img/icons/open-ticket.svg";
import ActiveTickets from "../img/icons/ActiveTickets.svg";
import CloseTicketIcon from "../img/icons/close-ticket.svg";
// dashboard
import TotalUsers from "../img/icons/total-users.svg";
import TotalTickets from "../img/icons/total-tickets.svg";
import TotalSettings from "../img/icons/total-settings.svg";
import TotalEarnings from "../img/icons/total-earnings.svg";

// subcription
import PDF from "../img/icons/pdf.svg";
import SaveIcon from "../img/icons/save-icon.svg";
import EditPencil from "../img/icons/edit-pencil.svg";
import CancelIcon from "../img/icons/cancel-icon.svg";
import HistoryInfo from "../img/icons/historyInfo.svg";
import RedCancelIcon from "../img/icons/red-cancel-icon.svg";
import GreenSaveIcon from "../img/icons/green-save-icon.svg";

// analytics
import LowIcon from "../img/icons/low.svg";
import MehIcon from "../img/icons/meh.svg";
import OkayIcon from "../img/icons/okay.svg";
import GreatIcon from "../img/icons/great.svg";
import SleepIcon from "../img/icons/sleep.svg";
import UnWellIcon from "../img/icons/unwell.svg";
import StressIcon from "../img/icons/stress.svg";
import HomeLifeIcon from "../img/icons/home.svg";
import RelationIcon from "../img/icons/relation.svg";
import StugglingIcon from "../img/icons/struggling.svg";
import MentleHealth from "../img/icons/mental-health.svg";
import BereavementIcon from "../img/icons/bereavement.svg";
import CLOSE from "../img/icons/cross.svg";
import REDCLOSE from "../img/icons/RedCross.svg";
import FILTER from "../img/icons/filter.svg";
import CalenderIcon from "../img/icons/calender.svg";
import AllSettingIcon from "../img/icons/all-settings.svg";
import AllDepartmentIcon from "../img/icons/all-departments.svg";
import AllCampusIcon from "../img/icons/all-campuses.svg";

export const images = {
  PDF,
  logo,
  info,
  info,
  NewLogo,
  Open,
  CLOSE,
  Crown,
  logout,
  Recipt,
  Closed,
  REDCLOSE,
  CalenderIcon,
  AllSettingIcon,
  AllDepartmentIcon,
  AllCampusIcon,
  FILTER,
  Camera,
  Tickets,
  LowIcon,
  MehIcon,
  Comment,
  SaveIcon,
  OkayIcon,
  Setting2,
  Setting1,
  ArrowDown,
  GreatIcon,
  ArrowRise,
  SleepIcon,
  UnWellIcon,
  CancelIcon,
  GreatEmoji,
  TotalUsers,
  Users_Icon,
  ActiveOpen,
  authImgSvg,
  EditPencil,
  Users_icon,
  StressIcon,
  QuestionRed,
  CompanyIcon,
  Attachments,
  placeholder,
  HistoryInfo,
  QuestionBlue,
  MentleHealth,
  TicketDetail,
  ActiveClosed,
  HomeLifeIcon,
  TotalTickets,
  RelationIcon,
  RedCancelIcon,
  StugglingIcon,
  GreenSaveIcon,
  ActiveTickets,
  QuestionGreen,
  TotalSettings,
  TotalEarnings,
  Settings_Icon,
  Payments_Icon,
  Analytics_Icon,
  OpenTicketIcon,
  Dashboard_Icon,
  CloseTicketIcon,
  BereavementIcon,
  PaymentCardIcon,
  PaymentCardIcon2,
  Notification_Icon,
  Subscription_Icon,
  SupportTicket_Icon,
};
