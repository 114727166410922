import moment from "moment";
import Lottie from "react-lottie";
import Tabs from "../components/Tabs";
import ApiServices from "../api/services";
import { mediaUrl } from "../utils/config";
import ReactPaginate from "react-paginate";
import Heading from "../components/Heading";
import Skeleton from "react-loading-skeleton";
import { images } from "../assets/styles/Images";
import { useMediaQuery } from "react-responsive";
import React, { useEffect, useState } from "react";
import CardWidget from "../components/CardWidget";
import RangePicker from "../components/RangePicker";
import ReciptModal from "../components/ReciptModal";
import FilterSelect from "../components/FilterSelect";
import { useLoggedContext } from "../context/provider";
import animationData from "../assets/lotties/empty-folder";
import { useSortableData } from "../hooks/useSortableData";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, ChevronUpIcon } from "@heroicons/react/24/solid";

// Table Head
const TABLE_HEAD = ["Setting Name", "Subscription Package", "Amount", "Action"];

// Tabs
const tabs = [{ label: "Monthly" }, { label: "Yearly" }];

// Plan Categories
const categories = [
  { id: "all", color: "#07966B", label: "All" },
  { id: "basic", color: "#FCEBB0", label: "Basic" },
  { id: "premium", color: "#B6E7CF", label: "Premium" },
  { id: "enterprise", color: "#FFBF9D", label: "Enterprise" },
  // { id: "ultra", color: "#264254", label: "Ultra" },
];

// Months
const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const Payment = () => {
  const { getPendingSettingsGlobal } = useLoggedContext();

  const todayStartDate = moment.utc().startOf("day");
  const todayEndDate = moment.utc().endOf("day");
  // Use States
  const [history, setHistory] = useState([]);
  const [balance, setBalance] = useState(null);
  const [balanceLoading, setBalanceLoading] = useState(false);
  const [historyLoading, setHistoryLoading] = useState(false);
  const [activeCategory, setActiveCategory] = useState("All");
  const [barChartLoading, setBarChartLoading] = useState(false);
  const [subscriptionAnalyticsData, setSubscriptionAnalyticsData] = useState(null);
  const [totalPageTransactionHistory, setTotalPageTransactionHistory] = useState(0);
  const [openReciptModal, setOpenReciptModal] = useState({ open: false, data: null });
  const [currentPageTransactionHistory, setCurrentPageTransactionHistory] = useState(1);
  const [activeSubscriptionAnalyticsTab, setActiveSubscriptionAnalyticsTab] = useState(tabs[0].label);

  const [dateRange, setDateRange] = useState({
    startDate: todayStartDate.unix(),
    endDate: todayEndDate.unix(),
  });

  const subscriptionAnalytics = months?.map((month) => ({
    name: month,
    Basic: 0,
    Premium: 0,
    Enterprise: 0,
    Ultra: 0,
  }));

  subscriptionAnalyticsData?.forEach((data) => {
    const date = new Date(data?.unix_created_at * 1000);

    const monthIndex = date.getMonth();
    const plan = data.plan_name;
    const price = parseFloat(data.price);

    subscriptionAnalytics[monthIndex][plan] += price;
  });

  // Graph Y Axis format
  const formatYAxis = (value) => `£${value}`;

  // Tooltip
  const formatTooltip = (value) => {
    return value.toFixed(2);
  };

  // Handle Category Click
  const handleCategoryClick = (category) => {
    setActiveCategory(category);
  };

  // Table Sorting
  const { items, requestSort, sortConfig } = useSortableData(history);

  // Media Queries
  const isLaptop = useMediaQuery({ minWidth: 1024 });
  const isLaptopMedium = useMediaQuery({ minWidth: 1536 });
  const isLargeScreenLaptop = useMediaQuery({ minWidth: 1700 });
  const itemsPerPage = isLargeScreenLaptop ? 11 : isLaptopMedium ? 6 : isLaptop ? 6 : 10;

  // Get Balance
  const getBalance = () => {
    let payload = {};

    setBalanceLoading(true);
    ApiServices.payments
      .getBalance(payload)
      .then((res) => {
        const { data, message } = res;

        if (data.code === 200) {
          setBalance(data.data);
          setBalanceLoading(false);
        } else {
          setBalance(null);
          setBalanceLoading(false);
        }
      })
      .catch((err) => {
        setBalance(null);
        setBalanceLoading(false);
      });
  };

  // Get History
  const getHistory = () => {
    let payload = {
      page: currentPageTransactionHistory,
      records_no: itemsPerPage,
    };

    setHistoryLoading(true);
    ApiServices.payments
      .getHistory(payload)
      .then((res) => {
        const { data, message } = res;
        if (data.code === 200) {
          setHistoryLoading(false);
          setHistory(data?.data?.data);
          setCurrentPageTransactionHistory(data?.data?.current_page);
          setTotalPageTransactionHistory(Math.ceil(data?.data?.total / data?.data?.per_page));
        } else {
          setHistory(null);
          setHistoryLoading(false);
        }
      })
      .catch((err) => {
        setHistory(null);
        setHistoryLoading(false);
      });
  };

  // Get Dashboard Subscription Analytics
  const getsubscriptionAnalytics = () => {
    setBarChartLoading(true);

    let payload;
    if (activeCategory === "All") {
      payload = {
        package: "",
        type: activeSubscriptionAnalyticsTab,
        start_date: dateRange.startDate ? dateRange.startDate : null,
        end_date: dateRange.endDate ? dateRange.endDate : null,
      };
    } else {
      payload = {
        package: activeCategory,
        type: activeSubscriptionAnalyticsTab,
        start_date: dateRange.startDate ? dateRange.startDate : null,
        end_date: dateRange.endDate ? dateRange.endDate : null,
      };
    }

    ApiServices.dashboard
      .getDashboardSubscriptionSales(payload)
      .then((res) => {
        // console.log("🚀 ~ .then ~ res:", res);
        const { data, message } = res;
        if (data.code === 200) {
          setSubscriptionAnalyticsData(data.data);
          setBarChartLoading(false);
        } else {
          setBarChartLoading(false);
        }
      })
      .catch((err) => {
        setBarChartLoading(false);
      });
  };

  // Pagination
  const handleAllUsersPageChange = ({ selected }) => {
    setCurrentPageTransactionHistory(selected + 1);
  };

  // Use Effects
  useEffect(() => {
    getsubscriptionAnalytics();
  }, [activeSubscriptionAnalyticsTab, activeCategory, dateRange]);

  useEffect(() => {
    getBalance();
  }, []);

  useEffect(() => {
    getHistory();
  }, [currentPageTransactionHistory]);

  useEffect(() => {
    getPendingSettingsGlobal();
  }, []);

  // Lottie Option
  const emptyFolderAnimation = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const { user } = useLoggedContext();

  // "super_panel_view_balance",
  // "super_panel_view_projection",
  // "super_panel_transaction_history",

  const hasPermissions = user?.permissions?.includes("super_panel_view_projection") || user?.permissions?.includes("super_admin_user");

  return (
    <>
      <div className="grid grid-cols-1 gap-5 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2">
        <div className="space-y-4">
          {/* Payments */}
          {(user?.permissions?.includes("super_panel_view_balance") ||
            user?.permissions?.includes("super_panel_view_projection") ||
            user?.role === "super_admin") && (
            <div className="space-y-4 card">
              <div className="flex">
                <Heading
                  heading="Payments"
                  subheading="All payment details, powered by stripe."
                  icon
                  handleRefresh={() => {
                    getBalance();
                  }}
                />
              </div>

              {/* Card Widget */}
              {balanceLoading ? (
                <div className="grid grid-cols-2 gap-7">
                  {(hasPermissions ? [...Array(2)] : [...Array(2)]).map((_, index) => (
                    <Skeleton key={index} count={1} height={180} borderRadius={16} />
                  ))}
                </div>
              ) : (
                <div className="grid grid-cols-2 gap-7">
                  {(user?.permissions?.includes("super_panel_view_balance") || user?.role === "super_admin") && (
                    <CardWidget
                      image={images.PaymentCardIcon}
                      title={`£${balance?.current_balance || "00"}`}
                      subtitle="Current Balance"
                      arrowText="+20.01"
                      targetUrl=" https://dashboard.stripe.com"
                      arrowTopRight
                    />
                  )}
                  {(user?.permissions?.includes("super_panel_view_projection") || user?.role === "super_admin") && (
                    <CardWidget
                      arrowTopRight
                      title={`£${balance?.projected_balance || "00"}`}
                      subtitle="Next Month Projections"
                      arrowText="+102.20"
                      targetUrl=" https://dashboard.stripe.com"
                      image={images.PaymentCardIcon2}
                      arrowBoxBorderColor="border-orange-600"
                      background="bg-lightRedColor2 hover:shadow-light-red-card hover:border-darkRedColor"
                    />
                  )}
                </div>
              )}
            </div>
          )}
          {/* Subscription Analytics */}
          <div className="card">
            <div className="flex justify-between">
              <div>
                <div className="flex gap-x-1">
                  <div className="card-title">Subscription Analytics</div>
                  <ArrowPathIcon
                    onClick={() => {
                      getsubscriptionAnalytics();
                    }}
                    className="w-5 h-5 mt-1 cursor-pointer text-grayColor300"
                  />
                </div>
                <div className="card-sub-title">highlights of subscriptions plans sales.</div>
              </div>
              <div>
                <div className="pb-3 pr-3">
                  <Tabs tabs={tabs} setActiveTab={setActiveSubscriptionAnalyticsTab} activeTab={activeSubscriptionAnalyticsTab} />
                </div>
              </div>
              <div>
                <RangePicker setRangePicker={setDateRange} width="3xl:w-76 2xl:w-40 xl:w-40" />
              </div>
            </div>

            <div className="flex mt-4">
              {categories.map((category) => (
                <div
                  key={category.id}
                  className="flex items-center justify-center cursor-pointer"
                  onClick={() => handleCategoryClick(category.label)}
                >
                  <div
                    className={`ml-3 mr-3 block h-3.5 w-3.5 shrink-0 rounded-full bg-${category.color} lg:mr-3 2xl:mr-2 2xl:h-4 2xl:w-4`}
                    style={{ backgroundColor: category.color }}
                  ></div>
                  <span
                    className={`${
                      activeCategory === category.label
                        ? "border-b-2 border-grayColor300 text-base font-semibold text-grayColor300"
                        : "text-base text-grayColor300"
                    }`}
                  >
                    {category.label}
                  </span>
                </div>
              ))}
            </div>

            {barChartLoading ? (
              <div className="grid grid-cols-1 gap-4">
                {[...Array(1)].map((_, index) => (
                  <Skeleton key={index} count={1} height={350} borderRadius={16} />
                ))}
              </div>
            ) : (
              <>
                <div className="flex -ml-12 mt-7">
                  <ResponsiveContainer width="100%" height={360}>
                    <BarChart
                      width={500}
                      height={300}
                      data={subscriptionAnalytics}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" vertical={false} />
                      <XAxis dataKey="name" tickLine={false} />
                      <YAxis tickLine={false} tickFormatter={formatYAxis} />
                      <Tooltip formatter={formatTooltip} />
                      {/* <Legend /> */}
                      <Bar dataKey="Basic" fill="#FCE2BF" radius={[12, 12, 0, 0]} />
                      <Bar dataKey="Premium" fill="#97F0C5" radius={[12, 12, 0, 0]} />
                      <Bar dataKey="Enterprise" fill="#FDAF9B" radius={[12, 12, 0, 0]} />
                      {/* <Bar dataKey="Ultra" fill="#264254" radius={[12, 12, 0, 0]} /> */}
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </>
            )}
          </div>
        </div>

        {/* Transactions History  */}
        {(user?.permissions?.includes("super_panel_transaction_history") || user?.role === "super_admin") && (
          <div className="card relative min-h-[83vh] space-y-6">
            <div className="flex items-center justify-between">
              <div className="flex gap-x-1">
                <Heading
                  heading="Transactions History"
                  icon
                  handleRefresh={() => {
                    getHistory();
                  }}
                />
              </div>
            </div>
            <div>
              <div className="mb-8 -mx-6 overflow-x-auto">
                <table className="w-full text-left">
                  <thead>
                    <tr>
                      {TABLE_HEAD?.map((head) => (
                        <th
                          key={head}
                          className="p-4 bg-white border-b border-gray-100 first:pl-6"
                          onClick={() => {
                            let sortKey;
                            if (head === "Setting Name") {
                              sortKey = "nursery.name";
                            } else if (head === "Subscription Package") {
                              sortKey = "plan_name";
                            } else if (head === "Amount") {
                              sortKey = "price";
                            } else {
                              sortKey = head.toLowerCase();
                            }

                            if (head !== "Action") {
                              requestSort(sortKey);
                            }
                          }}
                        >
                          <p className="text-xs font-semibold leading-5 cursor-pointer whitespace-nowrap font-inter 3xl:text-sm">
                            {head}

                            {head !== "Action" &&
                            sortConfig.key ===
                              (head === "Setting Name"
                                ? "nursery.name"
                                : head === "Subscription Package"
                                  ? "plan_name"
                                  : head === "Amount"
                                    ? "price"
                                    : head.toLowerCase()) &&
                            sortConfig.direction === "asc" ? (
                              <ChevronUpIcon className="inline-block h-4 w-3.5" />
                            ) : (
                              head !== "Action" && <ChevronDownIcon className="inline-block h-4 w-3.5" />
                            )}
                          </p>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {historyLoading ? (
                      <tr>
                        <td colSpan="6">
                          <Skeleton count={itemsPerPage} height={50} />
                        </td>
                      </tr>
                    ) : items?.length > 0 ? (
                      items?.map((item, index) => (
                        <tr key={item?.id} className="transition-all duration-300 even:bg-gray-50 hover:bg-secondary/10">
                          <td className="py-3 pl-4 3xl:px-4 3xl:first:pl-6">
                            <div className="flex items-center">
                              <div className="w-8 h-8 mr-3 rounded-full">
                                {/* <CustomImage placeholderImg={Images.PLACEHOLDER} imgSource={userUrl + item?.profile_url} alt="profile img" /> */}
                                <img
                                  className="w-8 h-8 mr-3 rounded-full"
                                  src={item?.user?.nursery[0]?.logo !== "" ? mediaUrl + item?.user?.nursery[0]?.logo : images?.placeholder}
                                  alt="Profile"
                                />
                              </div>
                              <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.user?.nursery[0]?.name}</p>
                            </div>
                          </td>

                          <td className="py-3 pl-4 3xl:px-4">
                            <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.plan_name + "/" + item?.plan_type}</p>
                          </td>

                          <td className="py-3 pl-4 3xl:px-4">
                            <p className="text-xs font-normal text-green-500 3xl:text-sm">£{item?.price}</p>
                          </td>

                          <td className="py-3 pl-4 pr-3 3xl:px-4">
                            <p
                              onClick={() => setOpenReciptModal({ open: true, data: item })}
                              className="text-xs font-medium underline cursor-pointer text-secondary 3xl:text-sm"
                            >
                              Receipt
                            </p>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className=" h-[500px]">
                        <td colSpan="6">
                          <Lottie options={emptyFolderAnimation} width={200} height={200} />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <div className="absolute bottom-4">
                <ReactPaginate
                  breakLabel="..."
                  pageRangeDisplayed={5}
                  marginPagesDisplayed={2}
                  activeClassName="active"
                  nextClassName="item next"
                  renderOnZeroPageCount={null}
                  pageCount={totalPageTransactionHistory}
                  breakClassName="item break-me"
                  containerClassName="pagination"
                  previousClassName="item previous"
                  forcePage={currentPageTransactionHistory - 1}
                  pageClassName="item pagination-page"
                  onPageChange={handleAllUsersPageChange}
                  nextLabel={<ChevronRightIcon className="w-5 h-5" />}
                  previousLabel={<ChevronLeftIcon className="w-5 h-5" />}
                />
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Recipt modal */}
      <ReciptModal
        data={openReciptModal.data}
        isOpen={openReciptModal.open}
        setIsOpen={(open) => setOpenReciptModal((prev) => ({ ...prev, open }))}
      />
    </>
  );
};

export default Payment;
