import { axios } from "../axios";
import { POST, PUT } from "../endpoints";

export const loginUser = async (data) => {
  try {
    let response = await axios.post(POST.LOGIN, data);
    return response;
  } catch (err) {
    throw err;
  }
};

export const forgotPassword = async (data) => {
  try {
    let response = await axios.post(POST.FORGOT_PASSWORD, data);
    return response;
  } catch (err) {
    throw err;
  }
};

export const resetPassword = async (data) => {
  try {
    let response = await axios.post(PUT.UPDATE_PASSWORD, data);
    return response;
  } catch (err) {
    throw err;
  }
};

export const changePassword = async (data) => {
  try {
    let response = await axios.post(POST.CHANGE_PASSWORD, data);
    return response;
  } catch (err) {
    throw err;
  }
};
