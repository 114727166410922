import Spinner from "./Spinner";
import Select from "react-select";
import ApiServices from "../api/services";
import { mediaUrl } from "../utils/config";
import camera from "../assets/img/camera.png";
import fileImage from "../assets/img/fileImage.png";
import countryList from "react-select-country-list";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useLoggedContext } from "../context/provider";
import { Fragment, useState, useEffect, useMemo } from "react";

const AddNewSettingModal = ({ isOpen, setIsOpen, data, editMode, onSuccess }) => {
  // Context
  const { btnLoading, setBtnLoading, openSuccessModal } = useLoggedContext();

  // Use State
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState(null);
  const [countryErr, setCountryErr] = useState("");
  const [settingName, setSettingName] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [contactPerson, setContactPerson] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [message, setMessage] = useState({ text: "", color: "" });
  const countriesList = useMemo(() => countryList().getData(), []);

  // Error Handling Use state
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [settingNameError, setSettingNameError] = useState("");
  const [selectedFileError, setSelectedFileError] = useState("");
  const [contactPersonError, setContactPersonError] = useState("");

  // Validations
  const isValidForm = () => {
    let isValidData = true;

    if (!settingName) {
      setSettingNameError("Required");
      isValidData = false;
    }

    if (!contactPerson) {
      setContactPersonError("Required");
      isValidData = false;
    }

    if (!email) {
      setEmailError("Required");
      isValidData = false;
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        setEmailError("Invalid Email Address");
        isValidData = false;
      }
    }

    if (!phone) {
      setPhoneError("Required");
      isValidData = false;
    } else if (!/^[0-9+()-\s]+$/.test(phone)) {
      setPhoneError("Phone Number Only");
      isValidData = false;
    }

    if (!country) {
      setCountryErr("Required");
      isValidData = false;
    }

    if (!address) {
      setAddressError("Required");
      isValidData = false;
    }

    if (!selectedImage) {
      setSelectedFileError("Please Upload a Image");
      isValidData = false;
    }

    return isValidData;
  };

  // Image Upload
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
      setSelectedFileError("");
      setImagePreview(URL.createObjectURL(file));
    }
  };

  // Image delete
  const handleImageDelete = () => {
    setSelectedImage(null);
    setImagePreview(null);
  };

  // const handleFormSubmit = async (event) => {
  //   const isValid = isValidForm();
  //   event.preventDefault();
  //   if (isValid) {
  //     const formData = new FormData();

  //     // Check if selectedImage is a valid File object

  //     if (selectedImage instanceof File) {
  //       formData.append("logo", selectedImage);
  //     }

  //     formData.append("name", settingName);
  //     formData.append("contact_person_name", contactPerson);
  //     if (!data) {
  //       formData.append("email", email);
  //     }
  //     formData.append("phone_number", phone);
  //     formData.append("country", country?.label);
  //     formData.append("address", address);

  //     try {
  //       if (data) {
  //         updateSetting(formData);
  //       } else {
  //         AddNewSetting(formData);
  //       }
  //     } catch (error) {
  //       console.error("Error updating/adding setting:", error);
  //     }
  //   } else {
  //     console.log("Form validation failed. Please check for errors.");
  //   }
  // };

  // const AddNewSetting = (formData) => {
  //   setBtnLoading(true);
  //   ApiServices.settings
  //     .AddNewSetting(formData)
  //     .then((res) => {
  //       const { data, message } = res;
  //       // console.log("Add Setting", data);

  //       if (data.code === 200) {
  //         setBtnLoading(false);
  //         closeModal();
  //         setActiveTab(1);
  //         onSuccess();
  //         openSuccessModal({
  //           title: "Success!",
  //           message: "Nursery added successfully.",
  //           onPress: (close) => {
  //             close();
  //             setActiveTab(1);
  //             onSuccess();
  //           },
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setMessage({
  //         text: err?.response?.data?.message,
  //         color: "#ed4337",
  //       });
  //       setBtnLoading(false);
  //     });
  // };

  // const updateSetting = (formData) => {
  //   setBtnLoading(true);
  //   ApiServices.settings
  //     .UpdateAddNewSetting(data?.id, formData)
  //     .then((res) => {
  //       const { data, message } = res;

  //       if (data.code === 200) {
  //         setBtnLoading(false);
  //         getListing();
  //         closeModal();
  //         openSuccessModal({
  //           title: "Success!",
  //           message: "Nursery updated successfully.",
  //           onPress: (close) => {
  //             close();
  //             getListing();
  //           },
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const isValid = isValidForm();
    if (!isValid) {
      console.log("Form validation failed. Please check for errors.");
      return;
    }

    const formData = new FormData();
    if (selectedImage instanceof File) {
      formData.append("logo", selectedImage);
    }
    formData.append("name", settingName);
    formData.append("contact_person_name", contactPerson);
    if (!data) {
      formData.append("email", email);
    }
    formData.append("phone_number", phone);
    formData.append("country", country?.label);
    formData.append("address", address);

    try {
      setBtnLoading(true);
      const response = data
        ? await ApiServices.settings.UpdateAddNewSetting(data?.nursery[0]?.id, formData)
        : await ApiServices.settings.AddNewSetting(formData);

      if (response.data.code === 200) {
        setBtnLoading(false);
        closeModal();
        onSuccess();
        openSuccessModal({
          title: "Success!",
          message: data ? "Nursery updated successfully." : "Nursery added successfully.",
          onPress: (close) => {
            close();
            onSuccess();
          },
        });
      }
    } catch (error) {
      console.error("Error updating/adding setting:", error);
      setBtnLoading(false);
      setMessage({
        text: error?.response?.data?.message || "An error occurred.",
        color: "#ed4337",
      });
    }
  };

  // useEffects
  useEffect(() => {
    if (data && editMode && isOpen) {
      setSettingName(data?.nursery[0]?.name);
      setEmail(data?.email);
      setAddress(data?.nursery[0]?.address);
      setPhone(data?.nursery[0]?.phone_number);
      setSelectedImage(data?.nursery[0]?.logo);
      setContactPerson(data?.nursery[0]?.contact_person_name);
      setCountry({ label: data?.nursery[0]?.country, value: data?.nursery[0]?.country });
      data?.nursery[0]?.logo ? setImagePreview(mediaUrl + data?.nursery[0]?.logo) : setImagePreview(null);
    } else {
      setSettingName("");
      setEmail("");
      setContactPerson("");
      setCountry(null);
      setAddress("");
      setPhone("");
    }
  }, [data, editMode, isOpen]);

  // Close Modal
  const closeModal = () => {
    setPhone("");
    setEmail("");
    setAddress("");
    setCountry(null);
    setIsOpen(false);
    setPhoneError("");
    setEmailError("");
    setCountryErr("");
    setSettingName("");
    setAddressError("");
    setContactPerson("");
    setBtnLoading(false);
    setImagePreview(null);
    setSelectedImage(null);
    setSettingNameError("");
    setSelectedFileError("");
    setContactPersonError("");
    setMessage({ message: "", color: "" });
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-75"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-75"
              >
                <Dialog.Panel className="w-full max-w-2xl p-8 overflow-hidden text-center align-middle transition-all bg-white shadow-xl rounded-2xl">
                  <div className="flex items-center justify-between">
                    <Dialog.Title as="h3" className="text-lg font-semibold leading-7 font-poppins text-secondary-color">
                      {data ? "Edit Setting" : "Add New Setting"}
                    </Dialog.Title>
                    <XMarkIcon onClick={closeModal} className="w-8 h-8 cursor-pointer text-info-color" />
                  </div>

                  <form encType="multipart/form-data" className="sm:mx-4" onSubmit={handleFormSubmit}>
                    <div className="text-center">
                      <div className="flex flex-col">
                        <div className="relative">
                          <div className="grid grid-cols-2 gap-4 text-center">
                            <div className="col-span-2 mx-auto my-5">
                              <div className="">
                                <div className="relative w-40 h-40 rounded-full group">
                                  {imagePreview ? (
                                    <>
                                      <img src={imagePreview} alt="selected img" className="object-cover w-full h-full rounded-full" />
                                      <div
                                        className="absolute top-0 right-0 flex items-center justify-center w-6 h-6 p-2 text-sm text-white transition-all duration-200 bg-red-500 rounded-full opacity-0 cursor-pointer font-poppins group-hover:opacity-100"
                                        onClick={handleImageDelete}
                                      >
                                        X
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <label
                                        htmlFor="image-upload"
                                        className="absolute top-0 right-0 flex items-center justify-center w-full h-full cursor-pointer"
                                      >
                                        <img src={fileImage} alt="add img" className="" />
                                      </label>
                                      <div className="absolute -bottom-4 -right-5">
                                        <input type="file" accept="image/*" id="image-upload" className="hidden" onChange={handleImageUpload} />
                                        <label htmlFor="image-upload">
                                          <img src={camera} alt="Camera Icon" className="cursor-pointer" />
                                        </label>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>

                              <div>{selectedFileError && <p className="col-span-2 mt-3 text-sm text-red-500">{selectedFileError}</p>}</div>
                            </div>
                            <div className="col-span-1 lg:col-span-1">
                              <div className="mb-2 ml-4 text-start text-[11px] text-grayColor300 xl:text-sm">
                                Setting Name <span className="text-red-600">*</span>
                                <span>{settingNameError && <span className="ml-1 text-sm text-left text-red-600">{settingNameError}</span>}</span>
                              </div>
                              <input
                                className=" placeholder:[#C5C5C5] w-full rounded-full border-[1px]  border-[#C5C5C5] px-6 py-3 text-sm font-normal text-[#474747]"
                                placeholder="Enter setting name"
                                type="text"
                                name="setting Name"
                                maxLength={25}
                                value={settingName}
                                onChange={(e) => {
                                  setSettingName(e.target.value);
                                  setSettingNameError("");
                                }}
                              />
                            </div>

                            <div className="col-span-1 lg:col-span-1 ">
                              <div className="mb-2 ml-4 text-start text-[11px] text-grayColor300 xl:text-sm">
                                Contact Person <span className="text-red-600">*</span>
                                <span>{contactPersonError && <span className="ml-1 text-sm text-left text-red-600">{contactPersonError}</span>}</span>
                              </div>
                              <input
                                className="placeholder:[#C5C5C5} w-full rounded-full border-[1px] border-[#C5C5C5] px-6 py-3 text-sm font-normal text-[#474747]"
                                placeholder="Enter contact person name"
                                type="text"
                                name="contact Person"
                                value={contactPerson}
                                onChange={(e) => {
                                  setContactPerson(e.target.value);
                                  setContactPersonError("");
                                }}
                              />
                            </div>
                            <div className="col-span-1 lg:col-span-1 ">
                              <div className="mb-2 ml-4 text-start text-[11px] text-grayColor300 xl:text-sm">
                                Phone Number <span className="text-red-600">*</span>
                                <span>{phoneError && <span className="ml-1 text-sm text-left text-red-600">{phoneError}</span>}</span>
                              </div>
                              <input
                                className="placeholder:[#C5C5C5] w-full rounded-full border-[1px] border-[#C5C5C5] px-6 py-3 text-sm font-normal text-[#474747]"
                                placeholder="Enter phone number"
                                type="tel"
                                name="phone"
                                value={phone}
                                onChange={(e) => {
                                  setPhone(e.target.value);
                                  setPhoneError("");
                                }}
                              />
                            </div>
                            <div className="col-span-1 lg:col-span-1 ">
                              <div className="mb-2 ml-4 text-start text-[11px] text-grayColor300 xl:text-sm">
                                Country <span className="text-red-600">*</span>
                                <span>{countryErr && <span className="ml-1 text-sm text-left text-red-600">{countryErr}</span>}</span>
                              </div>
                              <Select
                                options={countriesList}
                                value={country}
                                onChange={(value) => {
                                  setCountry(value);
                                  setCountryErr("");
                                }}
                                styles={customStyles}
                                className="text-left"
                                placeholder="Type to search your country"
                              />
                            </div>
                            <div className="col-span-1 lg:col-span-1">
                              <div className="mb-2 ml-4 text-start text-[11px] text-grayColor300 xl:text-sm">
                                Email <span className="text-red-600">*</span>
                                <span>{emailError && <span className="ml-1 text-sm text-left text-red-600">{emailError}</span>}</span>
                              </div>
                              <input
                                className={`${editMode && "bg-gray-200"} placeholder:primary-color-300 w-full rounded-full border-[1px] border-primary-color-300 px-6 py-3 text-sm text-secondary-color`}
                                placeholder="Enter email address"
                                type="text"
                                name="email"
                                value={email}
                                onChange={(e) => {
                                  setEmail(e.target.value);
                                  setEmailError("");
                                }}
                                readOnly={editMode ? true : false}
                              />
                            </div>

                            <div className="col-span-1 lg:col-span-1 ">
                              <div className="mb-2 ml-4 text-start text-[11px] text-grayColor300 xl:text-sm">
                                Address <span className="text-red-600">*</span>
                                <span>{addressError && <span className="ml-1 text-sm text-left text-red-600">{addressError}</span>}</span>
                              </div>
                              <input
                                className="placeholder:[#C5C5C5] w-full rounded-full border-[1px] border-[#C5C5C5] px-6 py-3 text-sm font-normal text-[#474747]"
                                placeholder="Enter address"
                                type="text"
                                name="address"
                                value={address}
                                onChange={(e) => {
                                  setAddress(e.target.value);
                                  setAddressError("");
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* message */}
                      <div className="mt-6 text-center">
                        <p className="text-sm font-medium text-red-500">{message?.text}</p>
                      </div>

                      {/* end message */}
                      <div className="grid grid-cols-2 mt-14 gap-x-8">
                        <button type="submit" className="rounded-full bg-secondary px-7 py-2.5 text-white">
                          {btnLoading ? <Spinner /> : data ? "Update" : "Add Setting"}
                        </button>
                        <button
                          type="button"
                          className="rounded-full border-2 border-secondary bg-white px-7 py-2.5 text-secondary   transition-colors duration-200 hover:bg-secondary hover:text-white "
                          onClick={closeModal}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default AddNewSettingModal;

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    height: "46px",
    minHeight: "46px",
    borderRadius: "4.375rem",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "46px",
    padding: "0 10px",
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0px",
    fontSize: "14px",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "46px",
  }),

  option: (provided, state) => ({
    ...provided,
    padding: "10px 12px",
    fontSize: "14px",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#474747",
    fontSize: "14px",
  }),
};
