import { useState, createContext, useContext, useEffect, useLayoutEffect } from "react";

// ===================== React Cookies ======================== //
import { useCookies } from "react-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import { superAdmin } from "../routes/routesDefined";
import SuccessModal from "../components/SuccessModal";
import ApiServices from "../api/services";
import { useMediaQuery } from "react-responsive";

const LocalContext = createContext();

export const useLoggedContext = () => {
  return useContext(LocalContext);
};

export const LocalProvider = (props) => {
  const [isLoggedin, setIsLoggedin] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [showSidebar, setShowSidebar] = useState("");
  // const [cookies, setCookies] = useCookies("token", "");
  const [sessionExpire, setSessionExpire] = useState(false);
  const [totalPendingRequests, setTotalPendingRequests] = useState(0);
  const [adminRole, setAdminRole] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalPagePendingSettings, setTotalPagePendingSettings] = useState(0);
  const [currentPagePendingSettings, setCurrentPagePendingSettings] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [pendingSettingsListing, setPendingSettingsListing] = useState([]);
  const [openModal, setOpenModal] = useState({ open: false, message: "", title: "" });
  const [user, setUser] = useState(() => JSON.parse(localStorage.getItem("userData")) || {});

  const [pendingCount, setPendingCount] = useState(0);

  let navigate = useNavigate();
  let location = useLocation()

  // Open Success Modal
  const openSuccessModal = ({ ...props }) => {
    setOpenModal({ ...openModal, ...props, open: true });
  };

  // Close Success Modal
  const closeSuccessModel = () => {
    setOpenModal({ ...openModal, open: false });
  };

  // Update User
  const updateUser = (newUserData) => {
    localStorage.setItem("userData", JSON.stringify(newUserData));
    setUser(newUserData);
  };

  // Media Queries
  const isLaptop = useMediaQuery({ minWidth: 1024 });
  const isLaptopMedium = useMediaQuery({ minWidth: 1536 });
  const isLargeScreenLaptop = useMediaQuery({ minWidth: 1700 });
  const itemsPerPage = isLargeScreenLaptop ? 9 : isLaptopMedium ? 6 : isLaptop ? 6 : 10;

  // Pending Settings Count
  const getPendingSettingsGlobal = () => {
    let payload = {
      // search: searchText,
      // page: currentPagePendingSettings,
      // records_no: itemsPerPage,
      status: "Pending",
    };

    ApiServices.settings
      .getSettings(payload)
      .then((res) => {
        // console.log(res);
        const { data } = res;
        if (data.code === 200) {
          setPendingCount(data?.data?.total);
          setPendingSettingsListing(data?.data?.data);
          setCurrentPagePendingSettings(data?.data?.current_page);
          setTotalPagePendingSettings(Math.ceil(data?.data?.total / data?.data?.per_page));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const status = JSON.parse(localStorage.getItem("loginStatus")) ? JSON.parse(localStorage.getItem("loginStatus")) : "";
    if (status === null || status === "undefined") {
      localStorage.setItem("loginStatus", JSON.stringify(false));
    }
    setIsLoggedin(status);
    expireSession();
    const interval = setInterval(() => {
      expireSession();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useLayoutEffect(() => {
    const superAdmin = localStorage.getItem("super_admin");
    setAdminRole(superAdmin);
  }, []);

  const login = (role) => {
    var now = new Date().getTime();
    localStorage.setItem("setupTime", now);
    localStorage.setItem("loginStatus", JSON.stringify(true));
    setIsLoggedin(true);
  };

  const logOut = () => {
    localStorage.setItem("loginStatus", JSON.stringify(false));
    setIsLoggedin(false);
    localStorage.clear();
    // setCookies("token", "");
    setAdminRole(false);
  };

  // ======================= Session Expire Function ====================== //
  const expireSession = () => {
    var hours = 2;
    var now = new Date().getTime();
    var setupTime = localStorage.getItem("setupTime");
    if (setupTime != null) {
      if (now - setupTime > hours * 60 * 60 * 1000) {
        setSessionExpire(true);
      }
    }
  };

  const adminType = (state) => {
    localStorage.setItem("super_admin", true);
    setAdminRole(state);
  };

  // useEffect(() => {
  //   getPendingSettingsGlobal();
  // }, [location.pathname]);

  // useEffect(()=>{
  //   getPendingSettingsGlobal()
  // },[])
  
  return (
    <LocalContext.Provider
      value={{
        isLoggedin,
        login,
        logOut,
        showSidebar,
        setShowSidebar,
        // cookies,
        // setCookies,
        sessionExpire,
        setSessionExpire,
        adminRole,
        openModal,
        setOpenModal,
        openSuccessModal,
        closeSuccessModel,
        showBackDrop,
        adminType,
        totalPagePendingSettings,
        currentPagePendingSettings,
        pendingSettingsListing,
        setShowBackDrop,
        btnLoading,
        setBtnLoading,
        loading,
        searchText,
        setSearchText,
        setCurrentPagePendingSettings,
        totalPendingRequests,
        setLoading,
        user,
        updateUser,
        pendingCount,
        getPendingSettingsGlobal,
      }}
    >
      {props.children && props.children}

      <SuccessModal />
    </LocalContext.Provider>
  );
};
