import Spinner from "./Spinner";
import ApiServices from "../api/services";
import { Fragment, useEffect, useState } from "react";
import { useLoggedContext } from "../context/provider";
import { Dialog, Transition } from "@headlessui/react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { EyeIcon, EyeSlashIcon, XMarkIcon } from "@heroicons/react/20/solid";

const ChangePassword = ({ isOpen, setIsOpen }) => {
  // Context
  const { logOut } = useLoggedContext();

  // Use States
  const [currentPwd, setCurrentPwd] = useState("");
  const [newPwd, setNewPwd] = useState("");
  const [confirmNewPwd, setConfirmNewPwd] = useState("");

  const [showCurrentPwd, setShowCurrentPwd] = useState(false);
  const [showNewPwd, setShowNewPwd] = useState(false);
  const [showConfirmNewPwd, setShowConfirmNewPwd] = useState(false);

  const [currentPwdError, setCurrentPwdError] = useState("");
  const [newPwdError, setNewPwdError] = useState("");
  const [confirmNewPwdError, setConfirmNewPwdError] = useState("");

  const [btnLoading, setBtnLoading] = useState(false);

  const [error, setError] = useState("");

  // Handle Form Validation
  const isValidForm = () => {
    let isValidData = true;
    setCurrentPwdError("");
    setNewPwdError("");
    setConfirmNewPwdError("");

    if (!currentPwd) {
      setCurrentPwdError("Required");
      isValidData = false;
    }

    if (!newPwd) {
      setNewPwdError("Required");
      isValidData = false;
    }

    if (!confirmNewPwd) {
      setConfirmNewPwdError("Required");
      isValidData = false;
    } else if (newPwd !== confirmNewPwd) {
      setConfirmNewPwdError("Passwords do not match");
      isValidData = false;
    }

    return isValidData;
  };

  // Close Modal
  const closeModal = () => {
    setIsOpen(false);
  };

  // Handle Change Password
  const handleChangePassword = () => {
    if (isValidForm()) {
      let payload = {
        password: currentPwd,
        new_password: newPwd,
        new_password_confirmation: confirmNewPwd,
      };

      setBtnLoading(true);
      ApiServices.auth
        .changePassword(payload)
        .then((response) => {
          if (response.data.code === 200) {
            setBtnLoading(false);
            closeModal();
            logOut();
          } else {
            setBtnLoading(false);
            setError("Invalid User");
            return;
          }
        })
        .catch((error) => {
          setError(error?.response?.data?.message);
          setBtnLoading(false);
        });
    }
  };

  // Use Effects
  useEffect(() => {
    if (isOpen) {
      setCurrentPwd("");
      setNewPwd("");
      setConfirmNewPwd("");
      setShowCurrentPwd(false);
      setShowNewPwd(false);
      setShowConfirmNewPwd(false);
      setCurrentPwdError("");
      setNewPwdError("");
      setConfirmNewPwdError("");
    }
  }, [isOpen]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-75"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-75"
              >
                <Dialog.Panel className="w-full max-w-lg p-8 overflow-hidden transition-all bg-white shadow-xl rounded-2xl">
                  <div className="flex items-center justify-between">
                    <Dialog.Title className="text-lg font-medium leading-7 text-left font-inter text-secondary">Change Password</Dialog.Title>
                    <XMarkIcon onClick={closeModal} className="w-8 h-8 cursor-pointer text-info-color" />
                  </div>
                  <div className="mt-8 text-left">
                    <label className="text-[#818181]">
                      Existing Password<span className="text-red-500">* {currentPwdError && currentPwdError}</span>
                    </label>
                    <div className="relative mt-2">
                      <input
                        type={showCurrentPwd ? "text" : "password"}
                        onChange={(e) => {
                          setCurrentPwd(e.target.value);
                          setCurrentPwdError("");
                          setError("");
                        }}
                        className={`text-grayColor500 text-md mb-4 flex w-full items-center rounded-full border border-grayColor100 p-3 px-5 leading-normal outline-none transition duration-150 ease-in-out md:p-3.5 md:px-6 lg:text-base`}
                        id="password"
                        placeholder="Password"
                        value={currentPwd}
                        autoComplete="off"
                        required
                      />
                      <i
                        onClick={() => setShowCurrentPwd(!showCurrentPwd)}
                        className="absolute right-5 top-[50%] translate-y-[-50%] cursor-pointer text-gray-50"
                      >
                        {showCurrentPwd ?  <EyeIcon className="w-5 h-5 text-secondary" /> :  <EyeSlashIcon className="w-5 h-5 text-secondary" />}
                      </i>
                    </div>

                    {/* New Password Field */}
                    <div className="relative mt-4">
                      <label className="text-[#818181]">
                        New Password<span className="text-red-500">* {newPwdError && newPwdError} </span>
                      </label>
                      <div className="relative mt-2">
                        <input
                          type={showNewPwd ? "text" : "password"}
                          onChange={(e) => {
                            setNewPwd(e.target.value);
                            setNewPwdError("");
                          }}
                          className={`text-grayColor500 text-md mb-4 flex w-full items-center rounded-full border border-grayColor100 p-3 px-5 leading-normal outline-none transition duration-150 ease-in-out md:p-3.5 md:px-6 lg:text-base`}
                          id="newPassword"
                          placeholder="New Password"
                          value={newPwd}
                          required
                        />
                        <i
                          onClick={() => setShowNewPwd(!showNewPwd)}
                          className="absolute right-5 top-[50%] translate-y-[-50%] cursor-pointer text-gray-50"
                        >
                          {showNewPwd ?  <EyeIcon className="w-5 h-5 text-secondary" /> :  <EyeSlashIcon className="w-5 h-5 text-secondary" />}
                        </i>
                      </div>
                    </div>
                    {/* Confirm New Password Field */}
                    <div className="relative mt-4">
                      <label className="text-[#818181]">
                        Confirm New Password<span className="text-red-500">* {confirmNewPwdError && confirmNewPwdError}</span>
                      </label>
                      <div className="relative mt-2">
                        <input
                          type={showConfirmNewPwd ? "text" : "password"}
                          onChange={(e) => {
                            setConfirmNewPwd(e.target.value);
                            setConfirmNewPwdError("");
                          }}
                          className={`text-grayColor500 text-md mb-4 flex w-full items-center rounded-full border border-grayColor100 p-3 px-5 leading-normal outline-none transition duration-150 ease-in-out md:p-3.5 md:px-6 lg:text-base`}
                          id="confirmNewPassword"
                          placeholder="Confirm New Password"
                          value={confirmNewPwd}
                          required
                        />
                        <i
                          onClick={() => setShowConfirmNewPwd(!showConfirmNewPwd)}
                          className="absolute right-5 top-[50%] translate-y-[-50%] cursor-pointer text-gray-50"
                        >
                          {showConfirmNewPwd ? (
                             <EyeIcon className="w-5 h-5 text-secondary" />
                          ) : (
                             <EyeSlashIcon className="w-5 h-5 text-secondary" />
                          )}
                        </i>
                      </div>
                      <small className="flex items-center justify-end gap-x-2 text-primary-color-100">
                       <InformationCircleIcon className="w-4 h-4 text-gray-500"/> You've been signed out.
                      </small>
                    </div>

                    {/* Error Message */}
                    {error && <div className="mt-2 text-red-500">{error}</div>}
                  </div>
                  <div className="flex px-8 mt-12 gap-x-6">
                    <button
                      type="button"
                      className={`inline-flex w-full justify-center rounded-full border border-transparent bg-secondary px-4 py-2.5 text-base font-medium text-white hover:opacity-75 focus:outline-none`}
                      onClick={handleChangePassword}
                    >
                      {btnLoading ? <Spinner /> : "Save"}
                    </button>

                    <button
                      type="button"
                      className={`inline-flex w-full justify-center rounded-full border border-secondary bg-transparent px-4 py-2.5 text-base font-medium text-secondary transition-all duration-300 hover:bg-secondary hover:text-white focus:outline-none`}
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ChangePassword;
