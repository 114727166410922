import moment from "moment";
import Lottie from "react-lottie";
import { Tooltip } from "react-tooltip";
import ApiServices from "../api/services";
import { mediaUrl, userMediaUrl } from "../utils/config";
import ReactPaginate from "react-paginate";
import Heading from "../components/Heading";
import Skeleton from "react-loading-skeleton";
import { images } from "../assets/styles/Images";
import React, { useEffect, useState } from "react";
import { useLoggedContext } from "../context/provider";
import { useSortableData } from "../hooks/useSortableData";
import animationData from "../assets/lotties/empty-folder";
import { ArrowPathIcon, CheckCircleIcon } from "@heroicons/react/24/outline";
import ConfirmationModal from "../components/ConfirmationModal";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { toUTCUnixTimestamp, getLocalDateFromUnixTimestamp } from "../helperFunctions/helper";
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import Spinner from "../components/Spinner";
import { useMediaQuery } from "react-responsive";
const TABLE_HEAD = ["Email", "Names", "Role", "Expires at"];

const rolesList = [
  // "super_admin_user",
  "super_panel_subscription_analytics",
  "super_panel_overview",
  "super_panel_ticket_analystics",
  "super_panel_subscription_sales",
  "super_panel_accept_decline_request",
  "super_panel_block_unblock_setting",
  "super_panel_add_new_setting",
  "super_panel_edit_setting",
  "super_panel_view_ticket",
  "super_panel_response_ticket",
  "super_panel_edit_delete_ticket",
  "super_panel_change_status",
  "super_panel_view_balance",
  "super_panel_view_projection",
  "super_panel_transaction_history",
  "super_panel_user",
  "super_panel_subcription_plan",
];

// Lottie Option
const emptyFolderAnimation = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Users = () => {
  // useState
  const { setBtnLoading, openSuccessModal, getPendingSettingsGlobal } = useLoggedContext();

  const [userName, setUserName] = useState("");
  const [loading, setLoading] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [editData, setEditData] = useState(null);
  const [activeRow, setActiveRow] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [showAddUser, setShowAddUser] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [localData, setLocalData] = useState({ roles: [] });
  const [allUsersListing, setAllUsersListing] = useState([]);
  const [showDatepicker, setShowDatepicker] = useState(false);
  const [totalPageAllUsers, setTotalPageAllUsers] = useState(0);
  const [currentPageAllUsers, setCurrentPageAllUsers] = useState(1);
  const [checkboxValues, setCheckboxValues] = useState(rolesList.map(() => false));
  const [openStatusModal, setOpenStatusModal] = useState({ open: false, data: null });
  const [message, setMessage] = useState({ text: "", color: "" });
  // Error useState
  const [userEmailError, setUserEmailError] = useState("");
  const [userNameError, setUserNameError] = useState("");
  // const [selectedFileError, setSelectedFileError] = useState("");
  const [selectedDateError, setSelectedDateError] = useState("");
  const [addNewUserLoading, setAddNewUserLoading] = useState(false);

  const { items, requestSort, sortConfig } = useSortableData(allUsersListing);

  const detail = allUsersListing?.find((item) => item?.id === (activeRow || allUsersListing[0]?.id));

  // Cancel Add User
  const handleCancelUserClick = () => {
    setShowAddUser(false);
    // setUserEmail("");
    setUserEmailError("");
    // setUserName("");
    setUserNameError("");
    setSelectedDateError("");
    // setSelectedFileError("");
    // setSelectedFile(null);
    setMessage({ text: "", color: "" });
    setLocalData((prevData) => {
      const updatedRoles = detail?.permissions || [];
      return { ...prevData, roles: updatedRoles };
    });

    setShowDatepicker(true);

    let unixTimestamp = detail.employee[0]?.expire_date;

    // Checking if the timestamp is in milliseconds
    if (unixTimestamp.toString().length > 10) {
      unixTimestamp = Math.floor(unixTimestamp / 1000);
    }

    // Checking if unixTimestamp is a number
    if (typeof unixTimestamp === "number" && !isNaN(unixTimestamp)) {
      // const dateObject = new Date(unixTimestamp * 1000);
      // const formattedDate = dateObject.toISOString().split("T")[0];
      setSelectedDate(moment.unix(detail?.employee[0]?.expire_date).format("YYYY-MM-DD"));
    }

    setEditData(null);

    getAllUser();
  };

  // console.log({ editData, showAddUser });

  // show datepicker
  const handleDateCheckboxChange = () => {
    setShowDatepicker(!showDatepicker);
  };

  // Validation
  const isValidForm = () => {
    let isValidData = true;

    if (!userName) {
      setUserNameError("Required");
      isValidData = false;
    }
    // if (!selectedFile) {
    //   setSelectedFileError("Required");
    //   isValidData = false;
    // }

    if (!selectedDate) {
      setSelectedDateError("Required");
      isValidData = false;
    }

    if (!userEmail) {
      setUserEmailError("Required");
      isValidData = false;
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(userEmail)) {
        setUserEmailError("Invalid email format");
        isValidData = false;
      }
    }

    return isValidData;
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
    setSelectedDateError("");
  };

  function getCurrentDate() {
    const today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1; // January is 0!
    const yyyy = today.getFullYear();

    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }

    return yyyy + "-" + mm + "-" + dd;
  }

  const AddNewUser = () => {
    if (!showAddUser) return;

    const isValid = isValidForm();

    if (!isValid) return;

    const formData = new FormData();
    formData.append("name", userName);
    formData.append("email", userEmail);
    formData.append("mode", "super_admin");
    selectedRoles?.forEach((role, index) => formData.append(`permissions[${index}]`, role));
    formData.append("profile_image_url", selectedFile || "");
    formData.append("expire_date", toUTCUnixTimestamp(selectedDate));

    setAddNewUserLoading(true);

    ApiServices.users
      .AddNewUser(formData)
      .then((res) => {
        const { data, message } = res;
        if (res.code === 200) {
          setAddNewUserLoading(false);
          setShowAddUser(false);
          getAllUser();
          openSuccessModal({
            title: "Success!",
            message: "User has been Created",
            onPress: (close) => {
              close();
            },
          });
        } else {
          setAddNewUserLoading(false);
        }
      })
      .catch((err) => {
        setMessage({
          text: err?.response?.data?.message,
          color: "#ed4337",
        });
        setAddNewUserLoading(false);
      });
  };

  const updateNewUser = () => {
    const formData = new FormData();
    formData.append("name", userName);
    // formData.append("email", userEmail);
    formData.append("mode", "super_admin");
    selectedRoles?.forEach((role, index) => {
      formData.append(`permissions[${index}]`, role);
    });
    formData.append("profile_image_url", selectedFile || "");
    formData.append("expire_date", toUTCUnixTimestamp(selectedDate));

    setAddNewUserLoading(true);

    ApiServices.users
      .updateNewUser(editData.id, formData)
      .then((res) => {
        const { data, message } = res;
        if (res.code === 200) {
          setAddNewUserLoading(false);
          setShowAddUser(false);
          getAllUser();
          openSuccessModal({
            title: "Success!",
            message: "User has been Updated",
            onPress: (close) => {
              close();
            },
          });
        } else {
          setAddNewUserLoading(false);
        }
      })
      .catch((err) => {
        setMessage({
          text: err?.response?.data?.message,
          color: "#ed4337",
        });
        setAddNewUserLoading(false);
      });
  };

  useEffect(() => {
    if (editData) {
      setUserName(editData?.employee[0]?.name || "");
      setUserEmail(editData.email || "");
      setShowDatepicker(true);
      let unixTimestamp = detail?.employee[0]?.expire_date;

      // Checking if the timestamp is in milliseconds
      if (unixTimestamp.toString().length > 10) {
        unixTimestamp = Math.floor(unixTimestamp / 1000);
      }

      // Checking if unixTimestamp is a number
      if (typeof unixTimestamp === "number" && !isNaN(unixTimestamp)) {
        // const dateObject = new Date(unixTimestamp * 1000); // Multiply by 1000 if it's in seconds
        // const formattedDate = dateObject.toISOString().split("T")[0];
        setSelectedDate(moment.unix(detail?.employee[0]?.expire_date).format("YYYY-MM-DD"));
      }

      // setSelectedDate(formattedDate);
      setSelectedFile(editData.image ? userMediaUrl + editData.image : "");

      setSelectedRoles((prevData) => {
        const updatedRoles = editData.permissions || [];
        return updatedRoles;
      });
      setLocalData((prevData) => {
        const updatedRoles = editData.permissions || [];
        return { ...prevData, roles: updatedRoles };
      });
    } else {
      setUserName("");
      setUserEmail("");
      setSelectedDate("");
      setSelectedFile("");
      setLocalData({
        roles: [],
      });
    }
  }, [editData]);

  const isLaptop = useMediaQuery({ minWidth: 1024 });
  const isLaptopMedium = useMediaQuery({ minWidth: 1536 });
  const isLargeScreenLaptop = useMediaQuery({ minWidth: 1700 });
  const itemsPerPage = isLargeScreenLaptop ? 10 : isLaptopMedium ? 10 : isLaptop ? 10 : 8;

  const getAllUser = async (emptySearch) => {
    setLoading(true);
    let payload = {
      search: emptySearch ? "" : searchText,
      page: currentPageAllUsers,
      records_no: itemsPerPage,
    };

    const res = await ApiServices.users
      .getUsers(payload)
      .then((res) => {
        const { data, message } = res;
        if (data.code === 200) {
          setLoading(false);
          setAllUsersListing(data?.data?.data);
          setCurrentPageAllUsers(data?.data?.current_page);
          setTotalPageAllUsers(Math.ceil(data?.data?.total / data?.data?.per_page));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Page Change
  const handleAllUsersPageChange = ({ selected }) => {
    setCurrentPageAllUsers(selected + 1);
  };

  // Delete User
  const handleDeleteUser = () => {
    const formData = new FormData();
    formData.append("id", detail?.id);

    setBtnLoading(true);
    ApiServices.users
      .deleteUser(formData)
      .then((res) => {
        if (res?.code === 200) {
          setOpenStatusModal({ open: false, data: null });
          setBtnLoading(false);
          getAllUser();
        } else {
          setBtnLoading(false);
        }
      })
      .catch(({ response }) => {
        setOpenStatusModal({ open: false, data: null });
        setBtnLoading(false);
        getAllUser();
      });
  };

  // Use Effects
  useEffect(() => {
    getAllUser();
  }, [currentPageAllUsers]);

  useEffect(() => {
    setActiveRow(items[0]?.id);
  }, [items]);

  const handleAddUserClick = () => {
    setShowAddUser(true);
    setShowDatepicker(false);
    setUserName("");
    setUserEmail("");
    setSelectedFile(null);
    setSelectedDate("");
    setLocalData((prevData) => ({
      ...prevData,
      roles: [],
    }));
    setCheckboxValues(rolesList.map(() => false));
    setSelectedRoles([]);
  };

  // Related to checkbox Permissions

  // useEffect(() => {
  //   const updatedCheckboxValues = rolesList?.map((role) => localData?.roles?.includes(role));
  //   setCheckboxValues(updatedCheckboxValues);
  // }, [localData?.roles]);

  useEffect(() => {
    if (Array.isArray(localData?.roles)) {
      const updatedCheckboxValues = rolesList?.map((role) => localData.roles.includes(role));
      setCheckboxValues(updatedCheckboxValues);
    }
  }, [localData?.roles, rolesList]);

  const handleSelectAllChange = () => {
    const selectAllValue = !checkboxValues.every((value) => value);
    setCheckboxValues(rolesList.map(() => selectAllValue));
    setSelectedRoles(selectAllValue ? rolesList : []);
  };

  const handleCheckboxChange = (index) => {
    setCheckboxValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = !newValues[index];

      // Update selected roles state based on the checked checkboxes
      const updatedSelectedRoles = rolesList.filter((role, i) => newValues[i]);
      setSelectedRoles(updatedSelectedRoles);

      return newValues;
    });
  };

  // Handle Search
  const handleSearch = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (searchText.trim() !== "") {
        await getAllUser();
        setCurrentPageAllUsers(1);
      }
    }
  };

  // Use Effects

  useEffect(() => {
    setLocalData((prevData) => {
      const updatedRoles = detail?.permissions || [];
      return { ...prevData, roles: updatedRoles };
    });

    setShowDatepicker(true);

    if (detail?.employee && detail.employee[0]?.expire_date) {
      let unixTimestamp = detail.employee[0].expire_date;

      // Checking if the timestamp is in milliseconds
      if (unixTimestamp.toString().length > 10) {
        unixTimestamp = Math.floor(unixTimestamp / 1000);
      }

      // Checking if unixTimestamp is a number
      if (typeof unixTimestamp === "number" && !isNaN(unixTimestamp)) {
        // const dateObject = new Date(unixTimestamp * 1000); // Multiply by 1000 if it's in seconds
        // const formattedDate = dateObject.toISOString().split("T")[0];
        setSelectedDate(moment.unix(detail.employee[0].expire_date).format("YYYY-MM-DD"));
      }
    }

    // if (detail?.employee && detail.employee[0]?.expire_date) {
    //   const unixTimestamp = detail.employee[0].expire_date;
    //   const dateObject = new Date(unixTimestamp);
    //   const formattedDate = dateObject.toISOString().split("T")[0];
    //   setSelectedDate(formattedDate);
    // }

    //   if (detail?.employee && detail.employee[0]?.expire_date) {
    //     const unixTimestamp = detail.employee[0].expire_date;

    //     // Creating a date object using the timestamp
    //     const dateObject = new Date(unixTimestamp);

    //     // Adjusting the date object to Pakistan Standard Time (PKT)
    //     dateObject.toLocaleString('en-US', { timeZone: 'Asia/Karachi' });

    //     // Formatting the date
    //     const formattedDate = dateObject.toLocaleDateString('en-PK', { year: 'numeric', month: '2-digit', day: '2-digit' });

    //     setSelectedDate(formattedDate);
    // }
  }, [detail]);
  useEffect(() => {
    getPendingSettingsGlobal();
  }, []);
  return (
    <div className="grid gap-5 md:grid-cols-1 xl:grid-cols-2">
      <div className="card min-h-[83vh] space-y-6">
        <div className="flex items-center justify-between">
          <div className="flex w-32 3xl:w-auto">
            <Heading
              heading="Users"
              subheading="Details of all users & permissions."
              icon
              handleRefresh={() => {
                getAllUser();
              }}
            />
          </div>
          <div className="flex gap-x-1 2xl:gap-x-4">
            <button onClick={handleAddUserClick} className="py-2 text-sm text-white rounded-full bg-secondary px-7 " type="button">
              Add User
            </button>
            <div className="relative flex items-center ">
              <div className="absolute inset-y-0 left-0 z-10 flex items-center pl-4 pointer-events-none">
                <MagnifyingGlassIcon className="w-5 h-5 text-primary-color-100" />
              </div>
              <input
                type="text"
                id="search"
                name="search"
                placeholder="Search..."
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  if (e.target.value.trim() === "") {
                    getAllUser(true);
                  }
                }}
                onKeyDown={handleSearch}
                className="block w-full px-4 py-2 text-sm border rounded-full focus:ring-primary-color border-grayColor200 pl-11 text-primary-color-100 focus:border-primary-color-100"
              />
            </div>
          </div>
        </div>

        <>
          <div className="mb-8 -mx-6 overflow-x-auto">
            <table className="w-full text-left">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head) => (
                    <th
                      key={head}
                      className="p-4 bg-white border-b border-gray-100 first:pl-6"
                      onClick={() => {
                        requestSort(head === "Phone" ? "phone_number" : head.toLowerCase());
                      }}
                    >
                      <p className="text-xs font-semibold leading-5 cursor-pointer whitespace-nowrap font-inter 3xl:text-sm">
                        {head}
                        {sortConfig.key === (head === "Phone" ? "phone_number" : head.toLowerCase()) && sortConfig.direction === "asc" ? (
                          <ChevronUpIcon className="inline-block h-4 w-3.5" />
                        ) : (
                          <ChevronDownIcon className="inline-block h-4 w-3.5" />
                        )}
                      </p>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="6">
                      <Skeleton count={itemsPerPage} height={50} />
                    </td>
                  </tr>
                ) : items.length > 0 ? (
                  items.map((item, index) => (
                    <tr
                      key={item?.id}
                      className={`cursor-pointer ${item?.id === activeRow ? "border-l-4 border-secondary bg-gray-50" : "hover:bg-gray-50"}`}
                      onClick={() => setActiveRow(item?.id)}
                    >
                      <td className="py-3 pl-4 3xl:px-4">
                        <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.email || "N/A"}</p>
                      </td>
                      <td className="py-3 pl-4 3xl:px-4 3xl:first:pl-6">
                        <div className="flex items-center">
                          <div className="w-8 h-8 mr-3 rounded-full">
                            {/* <CustomImage placeholderImg={Images.PLACEHOLDER} imgSource={userUrl + item?.profile_url} alt="profile img" /> */}

                            <img
                              className="w-8 h-8 mr-3 rounded-full"
                              src={item?.employee[0]?.profile_image_url ? userMediaUrl + item?.employee[0]?.profile_image_url : images.placeholder}
                              alt="Profile"
                            />
                          </div>
                          <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.employee[0]?.name || "N/A"}</p>
                        </div>
                      </td>
                      <td className="py-3 pl-4 3xl:px-4">
                        <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">Admin</p>
                      </td>

                      <td className="py-3 pl-4 pr-3 3xl:px-4">
                        <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">
                          {moment.unix(item.employee[0]?.expire_date).format("D MMM YYYY")}
                        </p>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className=" h-[500px]">
                    <td colSpan="6">
                      {/* no data */}
                      <Lottie options={emptyFolderAnimation} width={200} height={200} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="absolute bottom-4">
            <ReactPaginate
              breakLabel="..."
              pageRangeDisplayed={5}
              forcePage={currentPageAllUsers - 1}
              marginPagesDisplayed={2}
              activeClassName="active"
              nextClassName="item next"
              renderOnZeroPageCount={null}
              breakClassName="item break-me "
              containerClassName="pagination"
              onPageChange={handleAllUsersPageChange}
              previousClassName="item previous"
              pageClassName="item pagination-page "
              pageCount={totalPageAllUsers}
              nextLabel={<ChevronRightIcon className="w-5 h-5" />}
              previousLabel={<ChevronLeftIcon className="w-5 h-5" />}
            />
          </div>
        </>
      </div>

      <div className="card min-h-[83vh]">
        {loading ? (
          <Skeleton count={10} height={50} />
        ) : (
          <>
            {showAddUser ? (
              <div className="space-y-6">
                <Heading heading="Add User" />
                <div className="flex items-center justify-between border-b gap-x-6 border-primary-color-300 pb-7">
                  <div className="relative">
                    <div className="w-16 h-16 rounded-full 3xl:h-20 3xl:w-20">
                      {selectedFile && (
                        <img
                          src={selectedFile ? URL.createObjectURL(selectedFile) : images.placeholder}
                          className="w-full h-full rounded-full"
                          alt="Upload Image"
                        />
                      )}

                      {!selectedFile && (
                        <img
                          src={
                            editData?.employee[0]?.profile_image_url ? userMediaUrl + editData?.employee[0]?.profile_image_url : images.placeholder
                          }
                          className="w-full h-full rounded-full"
                          alt="Upload Image"
                        />
                      )}
                    </div>
                    <div className="absolute rounded-full -bottom-2 -right-6">
                      <input
                        type="file"
                        accept="image/png, image/jpeg, image/jpg"
                        onChange={(e) => {
                          setSelectedFile(e.target.files[0]);
                          // setSelectedFileError("");
                        }}
                        style={{ display: "none" }}
                        id="imageInput"
                      />
                      <label htmlFor="imageInput">
                        <img src={images.Camera} alt="Camera Icon" className="w-16 h-16 cursor-pointer" />
                      </label>
                    </div>
                  </div>

                  <div className="space-y-2 ">
                    <label htmlFor="userName" className="text-xs font-medium text-primary-color-100 3xl:text-sm">
                      <span> Name {userNameError && <span className="ml-1 text-red-600">{userNameError}</span>}</span>
                    </label>
                    <input
                      className="w-full px-6 py-2 text-sm font-normal border rounded-full border-primary-color-300 text-primary-color-100 placeholder:text-primary-color-300 3xl:py-3"
                      placeholder="Enter user name"
                      type="text"
                      name="userName"
                      maxLength={25}
                      value={userName}
                      onChange={(e) => {
                        setUserName(e.target.value);
                        setUserNameError("");
                      }}
                    />
                  </div>

                  <div className="space-y-2 ">
                    <label htmlFor="userEmail" className="text-xs font-medium text-primary-color-100 3xl:text-sm">
                      <span> Email {userEmailError && <span className="ml-1 text-red-600">{userEmailError}</span>}</span>
                    </label>
                    <input
                      className="w-full px-6 py-2 text-sm font-normal border rounded-full border-primary-color-300 text-primary-color-100 placeholder:text-primary-color-300 3xl:py-3"
                      placeholder="Enter user name"
                      type="text"
                      name="userEmail"
                      maxLength={255}
                      value={userEmail}
                      onChange={(e) => {
                        setUserEmail(e.target.value);
                        setUserEmailError("");
                      }}
                      disabled={editData ? true : false}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <>
                {!detail || Object.keys(detail).length === 0 ? (
                  <div className="absolute -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2">
                    <Lottie options={emptyFolderAnimation} width={200} height={200} />
                  </div>
                ) : (
                  <div className="flex items-start justify-between pb-4 border-b border-primary-color-300">
                    <div className="flex gap-x-3">
                      <div className="w-10 h-10 rounded-full shrink-0 3xl:h-16 3xl:w-16">
                        <img
                          src={detail?.employee[0]?.profile_image_url ? userMediaUrl + detail?.employee[0]?.profile_image_url : images.placeholder}
                          className="object-cover w-full h-full rounded-full"
                          alt="user"
                        />
                      </div>

                      <div className="space-y-1">
                        <h2 className="text-xl font-semibold text-secondary-color 3xl:text-3xl">{detail?.employee[0]?.name}</h2>
                        <p className="text-xs font-medium text-primary-color-200 3xl:text-sm">
                          {" "}
                          <CheckCircleIcon className={`inline h-4 w-4 ${detail?.status === 0 ? "text-red-600" : "text-accent"}`} />{" "}
                          {detail?.status === 0 ? "Deactive" : "Active"} - &nbsp;{detail?.email} - &nbsp;Expires at &nbsp;
                          <a id="date" className="inline-block cursor-pointer">
                            {moment.unix(detail?.employee[0]?.expire_date).format("D MMM YYYY")}
                          </a>
                          <Tooltip
                            anchorSelect="#date"
                            id="date-tooltip"
                            offset={5}
                            className="border border-gray-100 !bg-white !text-primary-color-100 shadow-card"
                            classNameArrow="hidden"
                            effect="solid"
                            place="bottom"
                          >
                            {/* Tooltip Content */}
                            <span>{moment.unix(detail?.employee[0]?.expire_date).format("D MMM YYYY")}</span>
                          </Tooltip>
                        </p>
                      </div>
                    </div>

                    <div className="space-x-3">
                      <span
                        className="text-xs underline cursor-pointer text-secondary 3xl:text-sm"
                        onClick={() => {
                          setEditData(detail);
                          setShowAddUser(true);
                        }}
                      >
                        Edit
                      </span>
                      <span
                        className="text-xs text-red-500 underline cursor-pointer 3xl:text-sm"
                        onClick={() => setOpenStatusModal({ open: true, data: null })}
                      >
                        Remove
                      </span>
                    </div>
                  </div>
                )}
              </>
            )}

            {/* <div className="bg-gray-100 absolute top-[7rem] left-[15rem] h-[70vh] w-[5rem] opacity-0"></div> */}
            {(detail || showAddUser) && (
              <div>
                {/* Date Checkbox */}
                <div className="flex py-6 border-b border-primary-color-300 3xl:py-8">
                  <div className="w-1/3">
                    <div className="flex items-center">
                      <h2 className="text-sm font-medium text-secondary-color 3xl:text-base">Access expiry </h2>
                      {selectedDateError && <span className="ml-1 text-sm text-red-600">{selectedDateError}</span>}
                    </div>
                  </div>
                  <div className="w-2/3 space-y-4">
                    <label htmlFor="Option1" className="flex items-center gap-4 cursor-pointer">
                      <input
                        onChange={handleDateCheckboxChange}
                        type="checkbox"
                        className="w-3 h-3 rounded border-primary-color-100 3xl:h-4 3xl:w-4"
                        id="Option1"
                        checked={showDatepicker}
                        disabled={editData || showAddUser ? "" : "disabled"}
                      />

                      <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">Set access expiry date </strong>
                    </label>

                    {showDatepicker && (
                      <input
                        id="date"
                        type="date"
                        name="date"
                        value={selectedDate}
                        onChange={handleDateChange}
                        min={getCurrentDate()}
                        className="h-8 text-xs border rounded-lg border-primary-color-100 3xl:h-auto 3xl:text-sm"
                        disabled={editData || showAddUser ? "" : "disabled"}
                      />
                    )}
                  </div>
                </div>

                {/* Permissions */}
                <div className="mt-4 space-y-2.5 pb-2">
                  <h2 className="text-base font-medium text-secondary-color 3xl:text-xl">Permissions</h2>
                  <p className="text-xs text-primary-color-100 3xl:text-sm">
                    Permission refers to the authorisation or rights granted to a user or a role to perform specific actions or access certain
                    functionalities within the application.
                  </p>
                </div>

                {/* scrolable div */}
                <div
                  className={`-mr-6 overflow-auto ${showAddUser && showDatepicker ? "h-20 xl:h-44 2xl:h-56" : showAddUser ? "h-80 xl:h-80 2xl:h-80" : showDatepicker ? "h-38 lg:h-full xl:h-[360px]" : "h-48 lg:h-full xl:h-[400px]"}`}
                >
                  {/* App access Checkbox */}
                  <div className={`flex border-b border-primary-color-300 py-6 3xl:py-8 `}>
                    <div className="w-1/3">
                      <h2 className="text-sm font-medium text-secondary-color 3xl:text-base">App Access</h2>
                    </div>
                    <div className="w-2/3">
                      <label htmlFor="appAccess" className="flex items-start gap-4 cursor-pointer">
                        <input
                          id="appAccess"
                          checked={checkboxValues.every((value) => value)}
                          onChange={handleSelectAllChange}
                          type="checkbox"
                          className="size-4 mt-1.5 rounded border-primary-color-100"
                          disabled={editData || showAddUser ? "" : "disabled"}
                        />
                        <div className="space-y-1">
                          <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">Admin (all permissions) </strong>
                          <p className="text-xs text-primary-color-100 3xl:text-sm">
                            All permission refers to the all authorisation or rights granted to admin to perform specific actions or access certain
                            functionalities within the application.
                          </p>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div>
                    {rolesList.map((role, index) => (
                      <>
                        <div className={`flex`}>
                          <div className={`w-1/3 pb-3 ${index === 0 && "pt-6"}`}>
                            {role === "super_panel_subscription_analytics" && (
                              <h2 className="text-sm font-medium text-secondary-color 3xl:text-base" key={index}>
                                Dashboard
                              </h2>
                            )}
                            {index === 4 && (
                              <div className="py-3 border-b border-primary-color-300 3xl:py-4">
                                {/* The border style will be applied for each category except the first one */}
                              </div>
                            )}
                            {role === "super_panel_accept_decline_request" && (
                              <h2 className={`text-sm font-medium text-secondary-color 3xl:text-base ${index === 4 && "pt-6"}`}>Settings</h2>
                            )}

                            {index === 8 && (
                              <div className="py-3 border-b border-primary-color-300 3xl:py-4">
                                {/* The border style will be applied for each category except the first one */}
                              </div>
                            )}

                            {role === "super_panel_view_ticket" && (
                              <h2 className={`text-sm font-medium text-secondary-color 3xl:text-base ${index === 8 && "pt-6"}`}>Support Tickets</h2>
                            )}
                            {index === 12 && (
                              <div className="py-3 border-b border-primary-color-300 3xl:py-4">
                                {/* The border style will be applied for each category except the first one */}
                              </div>
                            )}
                            {role === "super_panel_view_balance" && (
                              <h2 className={`text-sm font-medium text-secondary-color 3xl:text-base ${index === 12 && "pt-6"}`}>Payments</h2>
                            )}
                            {index === 15 && (
                              <div className="py-3 border-b border-primary-color-300 3xl:py-4">
                                {/* The border style will be applied for each category except the first one */}
                              </div>
                            )}
                            {role === "super_panel_user" && (
                              <h2 className={`text-sm font-medium text-secondary-color 3xl:text-base ${index === 15 && "pt-6"}`}>Users</h2>
                            )}
                            {index === 16 && (
                              <div className="py-3 border-b border-primary-color-300 3xl:py-4">
                                {/* The border style will be applied for each category except the first one */}
                              </div>
                            )}
                            {role === "super_panel_subcription_plan" && (
                              <h2 className={`text-sm font-medium text-secondary-color 3xl:text-base ${index === 16 && "pt-6"}`}>
                                Subscription Plans
                              </h2>
                            )}
                          </div>
                          <div className={`w-2/3 pb-3 ${index === 0 && "pt-6"}`}>
                            <div>
                              {role === "super_panel_subscription_analytics" && (
                                <>
                                  <label key={index} className="flex items-start gap-4 cursor-pointer">
                                    <input
                                      type="checkbox"
                                      checked={checkboxValues[index]}
                                      onChange={() => handleCheckboxChange(index)}
                                      className="size-4 mt-1.5 rounded border-primary-color-100"
                                      disabled={editData || showAddUser ? "" : "disabled"}
                                    />
                                    <div className="space-y-1">
                                      <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">View Subscription Analytics</strong>
                                      <p className="text-xs text-primary-color-100 3xl:text-sm">
                                        Capability to access detailed analytics on subscription plan usage, payments, and trends.
                                      </p>
                                    </div>
                                  </label>
                                </>
                              )}
                              {role === "super_panel_overview" && (
                                <>
                                  <label key={index} className="flex items-start gap-4 cursor-pointer">
                                    <input
                                      type="checkbox"
                                      checked={checkboxValues[index]}
                                      onChange={() => handleCheckboxChange(index)}
                                      className="size-4 mt-1.5 rounded border-primary-color-100"
                                      disabled={editData || showAddUser ? "" : "disabled"}
                                    />
                                    <div className="space-y-1">
                                      <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">View Overview</strong>
                                      <p className="text-xs text-primary-color-100 3xl:text-sm">
                                        Permission to access and view the comprehensive overview of key platform metrics and activities.
                                      </p>
                                    </div>
                                  </label>
                                </>
                              )}
                              {role === "super_panel_subscription_sales" && (
                                <>
                                  <label key={index} className="flex items-start gap-4 cursor-pointer">
                                    <input
                                      type="checkbox"
                                      checked={checkboxValues[index]}
                                      onChange={() => handleCheckboxChange(index)}
                                      className="size-4 mt-1.5 rounded border-primary-color-100"
                                      disabled={editData || showAddUser ? "" : "disabled"}
                                    />
                                    <div className="space-y-1">
                                      <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">
                                        View Subscription Plans Sales
                                      </strong>
                                      <p className="text-xs text-primary-color-100 3xl:text-sm">
                                        Authorisation to view detailed sales data for different subscription plans.
                                      </p>
                                    </div>
                                  </label>
                                </>
                              )}
                              {role === "super_panel_ticket_analystics" && (
                                <>
                                  <label key={index} className="flex items-start gap-4 cursor-pointer">
                                    <input
                                      type="checkbox"
                                      checked={checkboxValues[index]}
                                      onChange={() => handleCheckboxChange(index)}
                                      className="size-4 mt-1.5 rounded border-primary-color-100"
                                      disabled={editData || showAddUser ? "" : "disabled"}
                                    />
                                    <div className="space-y-1">
                                      <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">
                                        View Support Tickets Analytics
                                      </strong>
                                      <p className="text-xs text-primary-color-100 3xl:text-sm">
                                        Permission to access analytics related to support tickets, facilitation data-driven customer service.
                                      </p>
                                    </div>
                                  </label>
                                </>
                              )}
                              {index === 4 && (
                                <div className="py-3 border-b border-primary-color-300 3xl:py-4">
                                  {/* The border style will be applied for each category except the first one */}
                                </div>
                              )}
                              {role === "super_panel_accept_decline_request" && (
                                <>
                                  <label key={index} className={`flex cursor-pointer items-start gap-4 ${index === 4 && "pt-6"}`}>
                                    <input
                                      type="checkbox"
                                      checked={checkboxValues[index]}
                                      onChange={() => handleCheckboxChange(index)}
                                      className="size-4 mt-1.5 rounded border-primary-color-100"
                                      disabled={editData || showAddUser ? "" : "disabled"}
                                    />
                                    <div className="space-y-1">
                                      <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">
                                        Accept / Decline setting requests
                                      </strong>
                                      <p className="text-xs text-primary-color-100 3xl:text-sm">
                                        Permission to review and make decisions on incoming setting requests from companies.
                                      </p>
                                    </div>
                                  </label>
                                </>
                              )}
                              {role === "super_panel_block_unblock_setting" && (
                                <>
                                  <label key={index} className="flex items-start gap-4 cursor-pointer">
                                    <input
                                      type="checkbox"
                                      checked={checkboxValues[index]}
                                      onChange={() => handleCheckboxChange(index)}
                                      className="size-4 mt-1.5 rounded border-primary-color-100"
                                      disabled={editData || showAddUser ? "" : "disabled"}
                                    />
                                    <div className="space-y-1">
                                      <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">Block / Unblock settings</strong>
                                      <p className="text-xs text-primary-color-100 3xl:text-sm">
                                        Authority to restrict or restore access to specific settings.
                                      </p>
                                    </div>
                                  </label>
                                </>
                              )}
                              {role === "super_panel_add_new_setting" && (
                                <>
                                  <label key={index} className="flex items-start gap-4 cursor-pointer">
                                    <input
                                      type="checkbox"
                                      checked={checkboxValues[index]}
                                      onChange={() => handleCheckboxChange(index)}
                                      className="size-4 mt-1.5 rounded border-primary-color-100"
                                      disabled={editData || showAddUser ? "" : "disabled"}
                                    />
                                    <div className="space-y-1">
                                      <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">Add new setting</strong>
                                      <p className="text-xs text-primary-color-100 3xl:text-sm">
                                        Capability to initiate the registration process for new settings.
                                      </p>
                                    </div>
                                  </label>
                                </>
                              )}
                              {role === "super_panel_edit_setting" && (
                                <>
                                  <label key={index} className="flex items-start gap-4 cursor-pointer">
                                    <input
                                      type="checkbox"
                                      checked={checkboxValues[index]}
                                      onChange={() => handleCheckboxChange(index)}
                                      className="size-4 mt-1.5 rounded border-primary-color-100"
                                      disabled={editData || showAddUser ? "" : "disabled"}
                                    />
                                    <div className="space-y-1">
                                      <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">Edit setting</strong>
                                      <p className="text-xs text-primary-color-100 3xl:text-sm">
                                        Authorisation to modify details and information of existing settings.
                                      </p>
                                    </div>
                                  </label>
                                </>
                              )}
                              {index === 8 && (
                                <div className="py-3 border-b border-primary-color-300 3xl:py-4">
                                  {/* The border style will be applied for each category except the first one */}
                                </div>
                              )}
                              {role === "super_panel_view_ticket" && (
                                <>
                                  <label key={index} className={`flex cursor-pointer items-start gap-4 ${index === 8 && "pt-6"}`}>
                                    <input
                                      type="checkbox"
                                      checked={checkboxValues[index]}
                                      onChange={() => handleCheckboxChange(index)}
                                      className="size-4 mt-1.5 rounded border-primary-color-100"
                                      disabled={editData || showAddUser ? "" : "disabled"}
                                    />
                                    <div className="space-y-1">
                                      <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">View Tickets</strong>
                                      <p className="text-xs text-primary-color-100 3xl:text-sm">
                                        Access to view and monitor support tickets submitted by users or settings.
                                      </p>
                                    </div>
                                  </label>
                                </>
                              )}

                              {role === "super_panel_response_ticket" && (
                                <>
                                  <label key={index} className="flex items-start gap-4 cursor-pointer">
                                    <input
                                      type="checkbox"
                                      checked={checkboxValues[index]}
                                      onChange={() => handleCheckboxChange(index)}
                                      className="size-4 mt-1.5 rounded border-primary-color-100"
                                      disabled={editData || showAddUser ? "" : "disabled"}
                                    />
                                    <div className="space-y-1">
                                      <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">Respond ticket</strong>
                                      <p className="text-xs text-primary-color-100 3xl:text-sm">
                                        Permission to provide responses and solutions to support tickets.
                                      </p>
                                    </div>
                                  </label>
                                </>
                              )}

                              {role === "super_panel_edit_delete_ticket" && (
                                <>
                                  <label key={index} className="flex items-start gap-4 cursor-pointer">
                                    <input
                                      type="checkbox"
                                      checked={checkboxValues[index]}
                                      onChange={() => handleCheckboxChange(index)}
                                      className="size-4 mt-1.5 rounded border-primary-color-100"
                                      disabled={editData || showAddUser ? "" : "disabled"}
                                    />
                                    <div className="space-y-1">
                                      <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">Edit or Delete Respond</strong>
                                      <p className="text-xs text-primary-color-100 3xl:text-sm">
                                        Authority to modify or delete responses provided to support tickets, ensuring accurate and relevant
                                        communication.
                                      </p>
                                    </div>
                                  </label>
                                </>
                              )}
                              {role === "super_panel_change_status" && (
                                <>
                                  <label key={index} className="flex items-start gap-4 cursor-pointer">
                                    <input
                                      type="checkbox"
                                      checked={checkboxValues[index]}
                                      onChange={() => handleCheckboxChange(index)}
                                      className="size-4 mt-1.5 rounded border-primary-color-100"
                                      disabled={editData || showAddUser ? "" : "disabled"}
                                    />
                                    <div className="space-y-1">
                                      <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">Change status</strong>
                                      <p className="text-xs text-primary-color-100 3xl:text-sm">
                                        Authority to modify the status of support tickets, indicating progress or resolution.
                                      </p>
                                    </div>
                                  </label>
                                </>
                              )}
                              {index === 12 && (
                                <div className="py-3 border-b border-primary-color-300 3xl:py-4">
                                  {/* The border style will be applied for each category except the first one */}
                                </div>
                              )}
                              {role === "super_panel_view_balance" && (
                                <>
                                  <label key={index} className={`flex cursor-pointer items-start gap-4 ${index === 12 && "pt-6"}`}>
                                    <input
                                      type="checkbox"
                                      checked={checkboxValues[index]}
                                      onChange={() => handleCheckboxChange(index)}
                                      className="size-4 mt-1.5 rounded border-primary-color-100"
                                      disabled={editData || showAddUser ? "" : "disabled"}
                                    />
                                    <div className="space-y-1">
                                      <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">View current balance</strong>
                                      <p className="text-xs text-primary-color-100 3xl:text-sm">
                                        Authorisation to monitor the current financial balance of the platform.
                                      </p>
                                    </div>
                                  </label>
                                </>
                              )}
                              {role === "super_panel_view_projection" && (
                                <>
                                  <label key={index} className="flex items-start gap-4 cursor-pointer">
                                    <input
                                      type="checkbox"
                                      checked={checkboxValues[index]}
                                      onChange={() => handleCheckboxChange(index)}
                                      className="size-4 mt-1.5 rounded border-primary-color-100"
                                      disabled={editData || showAddUser ? "" : "disabled"}
                                    />
                                    <div className="space-y-1">
                                      <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">
                                        View next month's projections
                                      </strong>
                                      <p className="text-xs text-primary-color-100 3xl:text-sm">
                                        Access to projections for the upcoming month, aiding in financial planning.
                                      </p>
                                    </div>
                                  </label>
                                </>
                              )}
                              {role === "super_panel_transaction_history" && (
                                <>
                                  <label key={index} className="flex items-start gap-4 cursor-pointer">
                                    <input
                                      type="checkbox"
                                      checked={checkboxValues[index]}
                                      onChange={() => handleCheckboxChange(index)}
                                      className="size-4 mt-1.5 rounded border-primary-color-100"
                                      disabled={editData || showAddUser ? "" : "disabled"}
                                    />
                                    <div className="space-y-1">
                                      <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">View transactions history</strong>
                                      <p className="text-xs text-primary-color-100 3xl:text-sm">
                                        Permission to review a detailed history of financial transactions for transparency and auditing.
                                      </p>
                                    </div>
                                  </label>
                                </>
                              )}
                              {index === 15 && (
                                <div className="py-3 border-b border-primary-color-300 3xl:py-4">
                                  {/* The border style will be applied for each category except the first one */}
                                </div>
                              )}
                              {role === "super_panel_user" && (
                                <>
                                  <label key={index} className={`flex cursor-pointer items-start gap-4 ${index === 15 && "pt-6"}`}>
                                    <input
                                      type="checkbox"
                                      checked={checkboxValues[index]}
                                      onChange={() => handleCheckboxChange(index)}
                                      className="size-4 mt-1.5 rounded border-primary-color-100"
                                      disabled={editData || showAddUser ? "" : "disabled"}
                                    />
                                    <div className="space-y-1">
                                      <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">View Users</strong>
                                      <p className="text-xs text-primary-color-100 3xl:text-sm">Authorisation to monitor the user of the platform.</p>
                                    </div>
                                  </label>
                                </>
                              )}
                              {index === 16 && (
                                <div className="py-3 border-b border-primary-color-300 3xl:py-4">
                                  {/* The border style will be applied for each category except the first one */}
                                </div>
                              )}
                              {role === "super_panel_subcription_plan" && (
                                <>
                                  <label key={index} className={`flex cursor-pointer items-start gap-4 ${index === 16 && "pt-6"}`}>
                                    <input
                                      type="checkbox"
                                      checked={checkboxValues[index]}
                                      onChange={() => handleCheckboxChange(index)}
                                      className="size-4 mt-1.5 rounded border-primary-color-100"
                                      disabled={editData || showAddUser ? "" : "disabled"}
                                    />
                                    <div className="space-y-1">
                                      <strong className="text-xs font-medium text-primary-color-200 3xl:text-sm">View Subscription Plans</strong>
                                      <p className="text-xs text-primary-color-100 3xl:text-sm">
                                        Authorisation to monitor the Subscription plans of the platform.
                                      </p>
                                    </div>
                                  </label>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </div>

                {/* bottom button */}
                <div className="absolute bottom-0 left-0 z-10 flex items-center justify-between w-full px-6 py-3 bg-white shadow-card-bottom 3xl:py-4">
                  <div className="text-center">
                    <p className="text-sm font-medium text-red-500">{message?.text}</p>
                  </div>

                  {showAddUser ? (
                    <div className="flex items-center cursor-pointer gap-x-4">
                      <span onClick={handleCancelUserClick} className="text-sm font-medium text-secondary-color 3xl:text-base">
                        Cancel
                      </span>
                      {editData ? (
                        <button
                          onClick={updateNewUser}
                          className="px-12 py-2 text-sm text-white rounded-full bg-secondary 3xl:text-base"
                          type="button"
                        >
                          {addNewUserLoading ? <Spinner /> : "Update"}
                        </button>
                      ) : (
                        <button onClick={AddNewUser} className="px-12 py-2 text-sm text-white rounded-full bg-secondary 3xl:text-base" type="button">
                          {addNewUserLoading ? <Spinner /> : "Save"}
                        </button>
                      )}
                    </div>
                  ) : (
                    <></>
                    // <button onClick={handleSubmit} className="px-12 py-2 text-sm text-white rounded-full bg-primary-color-100 3xl:text-base" type="button">
                    //   Update
                    // </button>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>

      {/* Remove user modal */}
      <ConfirmationModal
        label="remove this user"
        isOpen={openStatusModal.open}
        handleSubmit={handleDeleteUser}
        setIsOpen={(open) => setOpenStatusModal((prev) => ({ ...prev, open }))}
      />
    </div>
  );
};

export default Users;
