import { axios } from "../axios";
import { POST, GET } from "../endpoints";

export const getSettings = async (data) => {
  try {
    let response = await axios.get(GET.GET_SETTINGS, {
      params: data,
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const getSettingsById = async (id) => {
  try {
    let response = await axios.get(`${GET.GET_SETTINGS}/${id}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const blockSetting = async (id) => {
  try {
    let response = await axios.get(`${GET.BLOCK_SETTING}/${id}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const acceptDeclineSetting = async (formData) => {
  try {
    let response = await axios.post(POST.ACCEPT_DECLINE, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const AddNewSetting = async (formData) => {
  try {
    let response = await axios.post(POST.ADD_NEW_SETTING, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const UpdateAddNewSetting = async (id, formData) => {
  try {
    let response = await axios.post(`${POST.UPDATE_ADD_NEW_SETTING}/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (err) {
    throw err;
  }
};
