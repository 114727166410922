import { Fragment } from "react";
import animationData from "../assets/lotties/empty-folder";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import Lottie from "react-lottie";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { useSortableData } from "../hooks/useSortableData";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { mediaUrl } from "../utils/config";
import { images } from "../assets/styles/Images";
import moment from "moment";
import { Tooltip } from "react-tooltip";
import Badge from "./Badge";

// Table Head
const TABLE_HEAD = ["Transaction ID", "Amount", "Subscription Package", "Status", "Received at"];

export default function TransactionHistoryModal({ data, isOpen, setIsOpen, handleSubmit }) {
  const [historyLoading, setHistoryLoading] = useState(false);

  // Close Modal
  function closeModal() {
    setIsOpen(false);
  }

  // Table Sorting
  const { items, requestSort, sortConfig } = useSortableData(data?.user?.transaction);
  // console.log("🚀 ~ TransactionHistoryModal ~ items:", items)

  // Lottie Option
  const emptyFolderAnimation = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-75"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-75"
              >
                <Dialog.Panel className="relative w-full max-w-4xl p-8 space-y-6 overflow-hidden transition-all bg-white shadow-xl rounded-2xl">
                  <Dialog.Title as="h3" className="flex items-center justify-between text-lg font-semibold leading-7 font-inter text-secondary-color">
                    Transactions History
                    <XMarkIcon onClick={closeModal} className="w-6 h-6 cursor-pointer text-primary-color-100" />
                  </Dialog.Title>
                  <div className="flex items-center gap-x-2">
                    <img src={data?.logo ? mediaUrl + data?.logo : images.placeholder} alt={data?.name} className="w-12 h-12 rounded-full" />
                    <h2 className="text-xl font-semibold font-inter text-secondary-color">{data?.name}</h2>
                  </div>
                  <div className="mb-8 -mx-8 overflow-x-auto">
                    <table className="w-full text-left">
                      <thead>
                        <tr>
                          {TABLE_HEAD.map((head) => (
                            <th
                              key={head}
                              className="p-4 bg-white border-b border-gray-100 first:pl-6"
                              onClick={() => {
                                let sortKey;
                                if (head === "Transaction ID") {
                                  sortKey = "transaction_id";
                                } else if (head === "Amount") {
                                  sortKey = "price";
                                } else if (head === "Subscription Package") {
                                  sortKey = "plan_name";
                                } else if (head === "Status") {
                                  sortKey = "payment_status";
                                } else {
                                  sortKey = head.toLowerCase();
                                }
                                requestSort(sortKey);
                              }}
                            >
                              <p className="text-xs font-semibold leading-5 cursor-pointer whitespace-nowrap font-inter 3xl:text-sm">
                                {head}
                                {sortConfig.key ===
                                  (head === "Transaction ID"
                                    ? "transaction_id"
                                    : head === "Amount"
                                      ? "price"
                                      : head === "Subscription Package"
                                        ? "plan_name"
                                        : head === "Status"
                                          ? "payment_status"
                                          : head.toLowerCase()) && sortConfig.direction === "asc" ? (
                                  <ChevronUpIcon className="inline-block h-4 w-3.5" />
                                ) : (
                                  <ChevronDownIcon className="inline-block h-4 w-3.5" />
                                )}
                              </p>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {historyLoading ? (
                          <tr>
                            <td colSpan="2">
                              <Skeleton count={4} height={50} />
                            </td>
                          </tr>
                        ) : items?.length > 0 ? (
                          items.map((item, index) => (
                            <tr key={item?.id} className="even:bg-gray-50">
                              <td className="py-3 pl-6">
                                <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.transaction_id || "N/A"}</p>
                              </td>
                              <td className="py-3 pl-4 pr-3 3xl:px-4">
                                <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{`£${item?.price}`}</p>
                              </td>

                              <td className="py-3 pl-4 pr-3 3xl:px-4">
                                <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{`${item?.plan_name}/${item?.plan_type}`}</p>
                              </td>

                              <td className="py-3 pl-4 pr-3 3xl:px-4">
                                <Badge status={item?.payment_status} />
                              </td>

                              <td className="py-3 pl-4 pr-3 3xl:px-4">
                                <p id={`date${index + 1}`} className="text-xs font-normal text-primary-color-200 3xl:text-sm">
                                  {moment.unix(item?.created_at_unix).format("D MMM YYYY")}
                                </p>
                                <Tooltip
                                  anchorSelect={`#date${index + 1}`}
                                  id={`date-tooltip${index + 1}`}
                                  offset={5}
                                  className="border border-gray-100 !bg-white !text-primary-color-100 !opacity-100 shadow-card"
                                  classNameArrow="hidden"
                                  effect="solid"
                                  place="bottom"
                                >
                                  {/* Tooltip Content */}
                                  <span>{moment.unix(item?.created_at_unix).format("D MMM YYYY h:mm A")}</span>
                                </Tooltip>
                              </td>
                            </tr>
                          ))
                        ) : (
                          // Render "No Data" message for active driver
                          <tr className="h-52">
                            <td colSpan="6">
                              <Lottie options={emptyFolderAnimation} width={200} height={200} />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
