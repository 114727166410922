import React from "react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";
import { images } from "../assets/styles/Images";
import { Tooltip } from "react-tooltip";

const CardWidget = ({ image, title, subtitle, background, arrowText, arrowColor, arrowTopRight, arrowImage, targetUrl, arrowBoxBorderColor }) => {
  return (
    <div
      className={`h-full space-y-4 border-2 border-transparent p-5 transition ${background ? background : "bg-secondary/10 hover:border-secondary hover:shadow-gray-card"} rounded-2xl`}
    >
      <img src={image} alt="Icon" />
      <div className="space-y-2">
        <h2 className="wh text-2xl font-bold text-secondary lg:text-2xl xl:text-lg 2xl:text-xl">{title ?? 0}</h2>
        <h4 className="text-base font-semibold text-primary-color-100 lg:text-base xl:text-xs 2xl:text-base">{subtitle}</h4>
        <div className="flex items-center justify-between">
          {/* <div className="flex items-center gap-x-3">
            <p className={`text-xs ${arrowColor ? arrowColor : "text-accent"}`}>{arrowText}</p>
            <img src={arrowImage ? arrowImage : images.ArrowRise} alt="arrow rise" />
          </div> */}

          {arrowTopRight && (
            <a href={targetUrl} target="_blank">
              <div
                className={`flex h-7 w-7 cursor-pointer items-center justify-center rounded-full border bg-white ${arrowBoxBorderColor ? arrowBoxBorderColor : "border-secondary"}`}
                id="stripe"
              >
                <ArrowTopRightOnSquareIcon className={`h-4 w-4 ${arrowBoxBorderColor ? "text-orange-600" : "text-secondary"}`} />
                <Tooltip
                  anchorSelect="#stripe"
                  id="date-tooltip-stripe"
                  offset={5}
                  className="z-10 border border-gray-100 !bg-white text-sm !text-primary-color-100 !opacity-100 shadow-card"
                  classNameArrow="hidden"
                  effect="solid"
                  place="bottom"
                >
                  {/* Tooltip Content */}
                  <span>Go to Stripe Dashboard</span>
                </Tooltip>
              </div>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardWidget;
