import { axios } from "../axios";
import { GET } from "../endpoints";

export const getBalance = async (data) => {
  try {
    let response = await axios.get(GET.GET_CURRENT_BALANCE, {
      params: data,
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const getHistory = async (data) => {
  try {
    let response = await axios.get(GET.GET_PAYMENT_HISTORY, {
      params: data,
    });
    return response;
  } catch (err) {
    throw err;
  }
};
