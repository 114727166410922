import React from "react";

// ===================== React Router DOM ===================== //
import { Navigate, Outlet, useLocation } from "react-router-dom";

// ===================== Context Provider ===================== //
import { useLoggedContext } from "../context/provider";

// ===================== Sidebar ===================== //
import Sidebar from "../components/Sidebar";

const ProtectedRoute = ({ RedirectPath, user }) => {
  const { isLoggedin, showSidebar } = useLoggedContext();
  const location = useLocation();

  if (!isLoggedin) {
    if (isLoggedin === null || isLoggedin === "undefined") {
      return <Navigate to={location.pathname} replace />;
    }
    return <Navigate to={RedirectPath} replace />;
  }

  return (
    <>
      <Sidebar logout={RedirectPath} />
      {/* <AdminNavbar logout={RedirectPath} /> */}
      <div className={isLoggedin ? "mb-8 mr-8 overflow-hidde md:mt-32 md:ml-8 lg:mt-28 lg:ml-68 3xl:ml-72" : "mt-0 md:ml-0"}>
        <Outlet />
      </div>
    </>
  );
};

export default ProtectedRoute;
