import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { images } from "../assets/styles/Images";
import UpdateProfileModal from "./UpdateProfileModal";
import ChangePassword from "./ChangePassword";
import { mediaUrl, userMediaUrl } from "../utils/config";
import { KeyIcon, UserIcon, DocumentTextIcon } from "@heroicons/react/24/outline";
import { useLoggedContext } from "../context/provider";
import ConfirmationModal from "./ConfirmationModal";

export default function Example() {
  const { user, logOut } = useLoggedContext();

  const [logoutAlert, setLogoutAlert] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);

  // Handle Logout
  const handleLogout = () => {
    setLogoutAlert(false);
    logOut();
  };

  return (
    <>
      <div className="text-right">
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button className="inline-flex items-center justify-center w-full">
              <div className="w-10 h-10 rounded-full 3xl:h-14 3xl:w-14">
                <img
                  className="object-cover w-full h-full rounded-full"
                  src={
                    user?.employee?.length === 0 || user?.employee[0]?.profile_image_url === null
                      ? images.placeholder
                      : userMediaUrl + user?.employee[0]?.profile_image_url
                  }
                  alt="placeholder"
                />
              </div>
              <ChevronDownIcon className="w-6 h-6 text-grayColor200" />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 p-4 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg w-60 ring-1 ring-black/5 focus:outline-none">
              <div className="flex flex-col items-center gap-y-2">
                <div className="w-12 h-12 rounded-full">
                  <img
                    className="object-cover w-full h-full rounded-full"
                    src={
                      user?.employee?.length === 0 || user?.employee[0]?.profile_image_url === null
                        ? images.placeholder
                        : userMediaUrl + user?.employee[0]?.profile_image_url
                    }
                    alt="placeholder"
                  />
                </div>
                <div className="space-y-1 text-center">
                  <h4 className="text-sm text-secondary-color">{user?.employee[0]?.name ? user?.employee[0]?.name : ""}</h4>
                  <p className="text-xs text-primary-color-100">{user?.email}</p>
                </div>
              </div>

              <div className="mt-4 border-b border-primary-color-300">
                <div className="mt-3">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => setShowProfileModal(true)}
                        className={`${active ? "bg-secondary text-white" : "text-primary-color-100"} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      >
                        {active ? <UserIcon className="w-4 h-4 mr-2" aria-hidden="true" /> : <UserIcon className="w-4 h-4 mr-2" aria-hidden="true" />}
                        Update Profile
                      </button>
                    )}
                  </Menu.Item>
                </div>

                <div>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${active ? "bg-secondary text-white" : "text-primary-color-100"} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        onClick={() => setShowPasswordModal(true)}
                      >
                        {active ? <KeyIcon className="w-4 h-4 mr-2" aria-hidden="true" /> : <KeyIcon className="w-4 h-4 mr-2" aria-hidden="true" />}
                        Change Password
                      </button>
                    )}
                  </Menu.Item>
                </div>

                <div>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="https://edufeel.co.uk/privacy-policy/"
                        target="_blank"
                        className={`${active ? "bg-secondary text-white" : "text-primary-color-100"} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      >
                        {active ? (
                          <DocumentTextIcon className="w-4 h-4 mr-2" aria-hidden="true" />
                        ) : (
                          <DocumentTextIcon className="w-4 h-4 mr-2" aria-hidden="true" />
                        )}
                        Privacy Policy
                      </a>
                    )}
                  </Menu.Item>
                </div>
              </div>

              <div className="px-2">
                <button className="flex mt-4 text-sm font-medium text-red-500 gap-x-2" onClick={() => setLogoutAlert(true)}>
                  <img src={images.logout} alt="logout icon" className="w-4 h-4" />
                  Sign Out
                </button>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>

      <UpdateProfileModal isOpen={showProfileModal} setIsOpen={setShowProfileModal} />

      <ChangePassword isOpen={showPasswordModal} setIsOpen={setShowPasswordModal} />

      {/* Logout Modal */}
      <ConfirmationModal label="Sign Out" isOpen={logoutAlert} setIsOpen={setLogoutAlert} handleSubmit={handleLogout} />
    </>
  );
}
